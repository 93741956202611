import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import { useGetAllpastpaperQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';

const Pastpapercard = (props) => {
  const slug = props.data;
  const pastpaper = useGetAllpastpaperQuery(slug);

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  if (pastpaper.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (pastpaper.data[0] == null)
    return (
      <div>

        <Error />
      </div>
    );
  if (pastpaper.isError) return <Error />;
  return (
    <>
      <Col sm={9}>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <Row>
              {pastpaper.data.map((paper) => {
                return (
                  <Col
                    sm={3}
                    id={slug}>
                    <div className="past-paper-filter-top-heading">
                      <h4 className="pb-60">
                        <Link to={'/Past-Paper-Question/' + paper.slug}>
                          {paper.name}
                        </Link>
                      </h4>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Collapse>
        <Collapse in={open1}>
          <div id="example-collapse-text-2">
            Anim pariatur cliche reprehenderit,
          </div>
        </Collapse>
      </Col>
    </>
  );
};

export default Pastpapercard;
