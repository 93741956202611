import React from 'react';
import './ap.css';
import Col from 'react-bootstrap/Col';
import Key from '../../img/icon/Molecule_light.png';
import Question from '../../img/icon/Quiz.png';
import Past from '../../img/icon/Book_open_alt_light.png';
import Quiz from '../../img/icon/Book.png';
import Key_w from '../../img/icon/Molecule_light_w.png';
import Question_w from '../../img/icon/Quiz_w.png';
import Past_w from '../../img/icon/Book_open_alt_light_w.png';
import Quiz_w from '../../img/icon/Book_w.png';
import { NavLink } from 'react-router-dom';
import { useGetAllcoursesQuery } from '../../services/aplms';
import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';


const Coursecard = (props) => {
  window.scrollTo(0, 0);
  const slug = props.propsData;
  const couresdata = useGetAllcoursesQuery(slug);
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (couresdata.isError) return <Error />;

  return (
    <>
      {couresdata.data.map((coures) => {
        return (
          <Col sm={3}>
            <div className="ap-box">
              <h4>{coures.name}</h4>
              <ul className="ap-box-content">
                <li className="ap-box-content-button">
                  <NavLink
                    to={'/Key-Concept/' + coures.slug}
                    className="courses-button-style">
                    <img
                      src={Key}
                      className="d"
                    />{' '}
                    <img src={Key_w} /> Key Concepts
                  </NavLink>
                </li>
                <li className="ap-box-content-button">
                  <NavLink
                    to={'/Question-Bank/' + coures.slug}
                    className="courses-button-style">
                    <img
                      src={Question}
                      className="d"
                    />
                    <img src={Question_w} /> Question Bank
                  </NavLink>
                </li>
                <li className="ap-box-content-button">
                  <NavLink
                    to={'/Past-Paper-Unit/' + coures.slug}
                    className="courses-button-style">
                    <img
                      src={Past}
                      className="d"
                    />{' '}
                    <img src={Past_w} /> Past Papers
                  </NavLink>
                </li>
                <li className="ap-box-content-button">
                  <NavLink
                    to={'/Quiz/' + coures.slug}
                    className="courses-button-style">
                    <img
                      src={Quiz}
                      className="d"
                    />{' '}
                    <img src={Quiz_w} /> Quizzes
                  </NavLink>
                </li>
              </ul>
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default Coursecard;
