import { React, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import logo_d from '../img/logo/black-logo.png';
import logo_w from '../img/logo/white-logo.png';
import Footer from './Footer';

import { getToken } from '../services/LocalStorageService';
import { useChangeUserPasswordMutation } from '../services/aplms';
import { useSelector } from 'react-redux';

const Reset = () => {
  const [error, setError] = useState({
    status: false,
    msg: '',
    type: '',
  });
  const [changeUserPassword] = useChangeUserPasswordMutation();
  const token = getToken();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const actualData = {
      password: data.get('password'),
      cpassword: data.get('cpassword'),
    };
    if (actualData.password && actualData.cpassword) {
      if (actualData.password === actualData.cpassword) {
        const res = await changeUserPassword({ actualData, token });
        console.log(res);
        if (res.data.status === 'success') {
          document.getElementById('password-change-form').reset();
          setError({
            status: true,
            msg: 'Password Changed Successful',
            type: 'success',
          });
        }
      } else {
        setError({
          status: true,
          msg: "Password and Confirm Password Doesn't Match",
          type: 'error',
        });
      }
    } else {
      setError({ status: true, msg: 'All Fields are Required', type: 'error' });
    }
  };

  const myData = useSelector((state) => state.user);
  return (
    <section className="login">
      <Container>
        <Row className="pb-60">
          <Col
            sm={12}
            className="width-from">
            <div className="login-top-logo">
              <img
                src={logo_w}
                className="drack"
              />
              <img
                src={logo_d}
                className="lite"
              />
            </div>
            <div className="login-from">
              {error.status ? (
                <p
                  className="p_alrat"
                  severity={error.type}>
                  {error.msg}
                </p>
              ) : (
                ''
              )}
              <Form
                autoComplete="off"
                onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-4"
                  controlId="formBasicEmail">
                  <Form.Label>Reset Password</Form.Label>
                  <Form.Control
                    placeholder="New Password"
                    name="password"
                    type="password"
                    id="password"
                  />
                </Form.Group>

                <Form.Group
                  className="mb-4"
                  controlId="formBasicPassword">
                  <Form.Label>Re-enter Password</Form.Label>
                  <Form.Control
                    name="cpassword"
                    type="password"
                    id="cpassword"
                    placeholder="Confirm New Password"
                  />
                </Form.Group>
                <Button
                  variant="primary"
                  type="submit">
                  Change
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
        <Footer />
      </Container>
    </section>
  );
};

export default Reset;
