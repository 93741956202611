import React from 'react';
import './SquaresArt.css'; // Import the CSS for styling

const SquaresArt = () => {
  const squareCount = 8;
  const size = 600;
  const centerX = 350;
  const centerY = 350;
  const borderRadius = 30; // Adjust this value for more or less rounding

  return (
    <svg className="squares-art" width="900" height="900">
      {Array.from({ length: squareCount }).map((_, index) => {
        // Adjust this scale factor for more gradual scaling
        const scale = Math.pow(0.97, index);
        const opacity = (squareCount - index) / squareCount;
        const squareSize = size * scale;

        return (
          <rect
            key={index}
            className={`square square-${index}`}
            x={centerX - squareSize / 2}
            y={centerY - squareSize / 2}
            width={squareSize}
            height={squareSize}
            fill="none"
            stroke="#B660DE"
            strokeWidth="2"
            rx={borderRadius}
            ry={borderRadius}
            style={{
              transformOrigin: `${centerX}px ${centerY}px`,
              opacity: opacity,
            }}
          />
        );
      })}
    </svg>
  );
};

export default SquaresArt;
