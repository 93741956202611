import React, { useState, useEffect } from "react";
import Isotope from "isotope-layout";
import "./Unit.css";
import { Link, useHistory } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Accordion from "react-bootstrap/Accordion";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Collapse from "react-bootstrap/Collapse";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import qu_top from "../img/icon/Quiz-bank-b.png";
import qu_top_w from "../img/icon/Quiz-bank.png";
import img_1 from "../img/icon/Arrow_right_light.png";
import img_2 from "../img/question-img-1.png";
import img_3 from "../img/icon/Video_light.png";
import img_4 from "../img/book-img.png";
import img_5 from "../img/icon/Arrow_right_light_w.png";
import img_6 from "../img/paper-photo.png";
import img_7 from "../img/for.png";
import EasyIcon from "../img/icon/EasyIcon.png";
import MediumIcon from "../img/icon/MediumIcon.png";
import HardIcon from "../img/icon/HardIcon.png";
import { useGetAlltopicQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Questionbankpaper from "./Questionbankpaper";
import Papercard from "./Papercard";
import HTMLReactParser from "html-react-parser";
import axios from "axios";
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import FormulasIcon from "../img/icon/FormulasIcon.png";

import { FaChevronRight } from "react-icons/fa6";

const Unit = (props) => {
  const history = useHistory();

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };

  const topicdata = useGetAlltopicQuery(props.match.params.slug);

  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);
  const [iso] = useState(null);
  const Easy = "Easy";
  const Medium = "Medium";
  const Hard = "Hard";
  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cardprops, setCardprops] = useState();
  const [fetchedData, setfetchedData] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [showFormulaModal, setShowFormulaModal] = useState(false);
  const [currentFormulaImage, setCurrentFormulaImage] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedDifficulty, setSelectedDifficulty] = useState("All"); // State to filter questions
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [currentVideoURL, setCurrentVideoURL] = useState(null);
  const [showMarkSchemeModal, setShowMarkSchemeModal] = useState(false);
  const [currentMarkSchemeImage, setCurrentMarkSchemeImage] = useState(null);

  const handleOpenMarkSchemeModal = (htmlString) => {
    const imageUrls = extractImageUrls(htmlString);
    if (imageUrls.length > 0) {
      setCurrentMarkSchemeImage(imageUrls);
      setShowMarkSchemeModal(true);
    } else {
      setCurrentMarkSchemeImage(null);
      setShowMarkSchemeModal(false);
    }
  };

  const handleCloseMarkSchemeModal = () => {
    setShowMarkSchemeModal(false);
    setCurrentMarkSchemeImage(null);
  };

  const handleOpenVideoModal = (videoURL) => {
    setCurrentVideoURL(videoURL);
    setShowVideoModal(true);
  };

  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setCurrentVideoURL(null);
  };

  const handleQuestionClick = (originalIndex) => {
    setSelectedQuestionIndex(originalIndex);

    const questionElement = document.getElementById(
      `question-${originalIndex}`
    );
    if (questionElement) {
      questionElement.scrollIntoView({
        behavior: "smooth",
        block: "center", // Aligns the question to the center of the viewport
      });
    }
  };
  const filteredQuestions = questions?.filter((question, index) => {
    question.originalIndex = index; // Store the original index
    if (selectedDifficulty === "All") return true;
    return question.status === selectedDifficulty;
  });

  const handleOpenFormulaModal = (formulaImage) => {
    setCurrentFormulaImage(formulaImage);
    setShowFormulaModal(true);
  };

  const extractImageUrls = (htmlString) => {
    const imgTags = htmlString.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
    if (!imgTags) return [];

    const srcRegex = /src="([^"]*)"/;
    return imgTags
      .map((imgTag) => {
        const match = imgTag.match(srcRegex);
        return match ? match[1] : null;
      })
      .filter(Boolean); // Remove null values
  };

  const handleCloseFormulaModal = () => {
    setShowFormulaModal(false);
    setCurrentFormulaImage(null);
  };

  const fetchQuestions = async (topicSlug) => {
    try {
      const response = await axios.get(
        `https://num8ers.cloud/api/questionbankquestion/${topicSlug}`
      );
      console.log("Fetched Questions:", response.data);
      setQuestions(response.data);
    } catch (err) {
      console.error("Error fetching questions:", err);
      setError(err.message);
    }
  };

  const fetchData = async () => {
    console.log("Slug value:", props.match.params.slug);

    try {
      const response = await axios.get(
        `https://num8ers.cloud/api/aptopicdata/${props.match.params.slug}`
      );
      console.log("Fetched Data:", response.data); // This will log the fetched data to the console
      setfetchedData(response.data);
      setCardprops(response.data[0].slug); // Assuming the API returns an array
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.match.params.slug]);

  useEffect(() => {
    if (topicdata.data && topicdata.data.length > 0) {
      const firstTopic = topicdata.data[0];
      setCardprops(firstTopic.slug);
      fetchQuestions(firstTopic.slug); // Fetch questions for the first topic by default
      setSelectedTopic(firstTopic.id); // Set the first topic as the selected one
    }
  }, [topicdata.data]); // Make sure this effect depends on `topicdata.data`

  if (topicdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (topicdata.isError)
    return <h1>An error occurred {topicdata.error.error}</h1>;

  return (
    <div className="questionbank-unit">
      <div className="top-header-quetion">
        <Header />
        <section className="ap">
          <Container>
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].curriculaname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={"/AP/" + topicdata.data[0].curriculaslug}>
                        {topicdata.data[0].subjname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(2)}>
                        {topicdata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {topicdata.data[0].unitname}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading">
                  <h2>{topicdata.data[0].couresname} - Question Bank</h2>
                  <Row>
                    <Col sm={8}>
                      <p>
                        {HTMLReactParser(topicdata.data[0].couresdescription)}
                      </p>
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center">
                        <img src={qu_top} className="d" />
                        <img src={qu_top_w} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="unit-page pt-60 pb-120">
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
        <Row>
          <Col sm={3} style={{ marginLeft: "70px" }}>
            <h3 className="unit-title">{topicdata.data[0].unitname}</h3>
            {topicdata.data.map((topic) => {
              return (
                <Button
                  key={topic.id}
                  onClick={() => {
                    setCardprops(topic.slug);
                    fetchQuestions(topic.slug);
                    setSelectedTopic(topic.id);
                  }}
                  variant="outline-primary"
                  className={`topic-button ${
                    selectedTopic === topic.id ? "selected" : ""
                  }`}
                >
                  <span className="topic-button-text">{topic.name}</span>
                  <FaChevronRight className="topic-button-icon" />
                </Button>
              );
            })}
          </Col>
          <Col sm={8}>
            <Col sm={10}>
              <div className="difficulty-filters">
                <Button
                  onClick={() => setSelectedDifficulty("Easy")}
                  className={selectedDifficulty === "Easy" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={EasyIcon} alt="Easy" />
                  Easy
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Medium")}
                  className={selectedDifficulty === "Medium" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={MediumIcon} alt="Medium" />
                  Medium
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("Hard")}
                  className={selectedDifficulty === "Hard" ? "active" : ""}
                  variant="outline-primary"
                >
                  <img src={HardIcon} alt="Hard" />
                  Hard
                </Button>
                <Button
                  onClick={() => setSelectedDifficulty("All")}
                  className={selectedDifficulty === "All" ? "active" : ""}
                  variant="outline-primary"
                >
                  All
                </Button>
              </div>
            </Col>

            {filteredQuestions && filteredQuestions.length > 0 ? (
              <div>
                <div className="question-list-scroll">
                  {filteredQuestions.map((question) => (
                    <Button
                      key={question.originalIndex}
                      onClick={() =>
                        handleQuestionClick(question.originalIndex)
                      }
                      className={`question-list-item ${
                        selectedQuestionIndex === question.originalIndex
                          ? "active"
                          : ""
                      }`}
                    >
                      Question {question.originalIndex + 1}
                    </Button>
                  ))}
                </div>

                {filteredQuestions.map((question) => (
                  <div
                    key={question.originalIndex}
                    className="questions-container"
                    id={`question-${question.originalIndex}`}
                  >
                    <div className="title-container">
                      <h3 className="h3-title">
                        Question {question.originalIndex + 1}
                      </h3>
                      {question.status === "Easy" && (
                        <img
                          src={EasyIcon}
                          alt="Easy Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Medium" && (
                        <img
                          src={MediumIcon}
                          alt="Medium Icon"
                          className="status-icon"
                        />
                      )}
                      {question.status === "Hard" && (
                        <img
                          src={HardIcon}
                          alt="Hard Icon"
                          className="status-icon"
                        />
                      )}
                    </div>

                    {question.formula && (
                      <Button
                        variant="link"
                        onClick={() => handleOpenFormulaModal(question.formula)}
                        style={{
                          fontSize: "16px",
                          fontFamily: "Nunito, sans-serif",
                          color: "black",
                          position: "absolute",
                          top: "2vh",
                          right: "2vw",
                          padding: "10px 20px",
                          backgroundColor: "rgba(63, 168, 26, 0.35)",
                          borderRadius: "10px",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          fontWeight: "600",
                        }}
                      >
                        Formulas
                      </Button>
                    )}

                    <div className="question-photo">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: question.description.replace(
                            /<img /g,
                            '<img style="max-width: 100%; height: auto; margin-top: 1vh; margin-bottom: 1vh; border-radius: 20px;" '
                          ),
                        }}
                      />
                    </div>

                    {/* New Buttons */}
                    <div
                      className="additional-buttons"
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="link"
                        onClick={() =>
                          handleOpenMarkSchemeModal(question.photo)
                        }
                        style={{
                          fontSize: "16px",
                          fontFamily: "Nunito, sans-serif",
                          color: "black",
                          padding: "20px 30px",
                          backgroundColor: "rgba(63, 168, 26, 0.35)",
                          borderRadius: "5px",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center", // Vertical centering
                          justifyContent: "center", // Horizontal centering
                          fontWeight: "600",
                          marginRight: "50px",
                          width: "20%",
                          textAlign: "center", // Ensure text alignment is centered
                        }}
                      >
                        Mark Scheme
                      </Button>

                      <Button
                        variant="link"
                        onClick={() => handleOpenVideoModal(question.video)}
                        style={{
                          fontSize: "16px",
                          fontFamily: "Nunito, sans-serif",
                          color: "black",
                          padding: "10px 20px",
                          backgroundColor: "rgba(63, 168, 26, 0.35)",
                          borderRadius: "5px",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center", // Vertical centering
                          justifyContent: "center", // Horizontal centering
                          fontWeight: "600",
                          marginRight: "50px",
                          width: "20%",
                          textAlign: "center", // Ensure text alignment is centered
                        }}
                      >
                        Video Answer
                      </Button>

                      <Button
                        variant="link"
                        onClick={() => alert("Similar Questions")}
                        style={{
                          fontSize: "16px",
                          fontFamily: "Nunito, sans-serif",
                          color: "black",
         
                          backgroundColor: "rgba(63, 168, 26, 0.35)",
                          borderRadius: "5px",
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center", // Vertical centering
                          justifyContent: "center", // Horizontal centering
                          fontWeight: "600",
                          width: "20%",
                          textAlign: "center", // Ensure text alignment is centered
                        }}
                      >
                        More Similar Questions
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No questions found with the current filter =(</p>
            )}
          </Col>
        </Row>
      </section>
      {/* Modal for viewing formula */}
      <Modal
        show={showFormulaModal}
        onHide={handleCloseFormulaModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Formula</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentFormulaImage && (
            <iframe
              src={currentFormulaImage}
              style={{ width: "100%", height: "600px" }}
              title="Formula PDF"
            />
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showVideoModal}
        onHide={handleCloseVideoModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Video Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentVideoURL ? (
            <div className="video-container">
              <iframe
                src={currentVideoURL}
                style={{ width: "100%", height: "400px" }}
                title="Video Answer"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <p>No video available.</p>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showMarkSchemeModal}
        onHide={handleCloseMarkSchemeModal}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Mark Scheme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentMarkSchemeImage && currentMarkSchemeImage.length > 0 ? (
            currentMarkSchemeImage.map((src, index) => (
              <img
                key={index}
                src={src}
                alt={`Mark Scheme ${index + 1}`}
                style={{ width: "100%", height: "auto", marginBottom: "10px" }}
              />
            ))
          ) : (
            <p>No Mark Scheme available.</p>
          )}
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  );
};

export default Unit;
