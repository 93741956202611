import React from 'react';
import Key from '../img/icon/Molecule_light.png';
import Question from '../img/icon/Quiz.png';
import Past from '../img/icon/Book_open_alt_light.png';
import Quiz from '../img/icon/Book.png';
import Key_w from '../img/icon/Molecule_light_w.png';
import Question_w from '../img/icon/Quiz_w.png';
import Past_w from '../img/icon/Book_open_alt_light_w.png';
import Quiz_w from '../img/icon/Book_w.png';
import './ap/ap.css';
import Course from './Course';
import { useGetAllsubjectQuery } from '../services/aplms';
import Error from '../errordata/Error';
import Loader from '../loader/Loader';
import Col from 'react-bootstrap/Col';
const Subject = (props) => {
  const slug = props.propsintroData;
  const couresdata = useGetAllsubjectQuery(slug);
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (couresdata.isError) return <Error />;
  return (
    <>
      {couresdata.data.map((subject) => {
        return (
          <Col sm={3}>
            <div className="ap-box">
              <h4> {subject.name}</h4>
              <Course propsData={subject.slug} />
            </div>
          </Col>
        );
      })}
    </>
  );
};

export default Subject;
