import { React, useState, useEffect } from 'react';
import Right_Arror from '../../img/icon/right-arror.png';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import './header.css';
import { useGetAllsubjectQuery } from '../../services/aplms';
import Right_Arror_d from '../../img/icon/rigth_arror_drack.png';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap';
import Ritsideheader from './Ritsideheader';
import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';
import { Link, useHistory } from 'react-router-dom';

import img11 from '../../img/404.png';
import img12 from '../../img/404-mobile.png';

const Sideheader = (props) => {
  const slug = props.propsintroData;
  const couresdata = useGetAllsubjectQuery(slug);

  // Set initial state to null or empty string
  const [selectedSlug, setSelectedSlug] = useState(null);

  // Use useEffect to update selectedSlug once data is available
  useEffect(() => {
    if (couresdata.data && couresdata.data.length > 0) {
      setSelectedSlug(couresdata.data[0].slug);
    }
  }, [couresdata.data]);

  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data && couresdata.data.length === 0) return <Error />;
  if (couresdata.isError) return <Error />;

  const handleNavLinkClick = (subjectSlug) => {
    setSelectedSlug(subjectSlug);
  };

  console.log(selectedSlug); // Check this log to verify if selectedSlug is correctly updated

  return (
    <>
      <Row>
        <Col sm={2}>
          {couresdata.data.map((subject) => {
            return (
              <Nav
                variant="pills"
                className="flex-column"
                key={subject.slug}>
                <Nav.Item className="megamenu-left-tab">
                  <Nav.Link
                    eventKey={subject.slug}
                    onClick={() => handleNavLinkClick(subject.slug)}
                    active={selectedSlug === subject.slug}>
                    {subject.name}
                    <img
                      src={Right_Arror}
                      className="d"
                      alt="Right Arrow"
                    />
                    <img
                      src={Right_Arror_d}
                      className="w"
                      alt="Right Arrow Dark"
                    />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            );
          })}
        </Col>
        <Col sm={10}>
          <Ritsideheader propsData={selectedSlug} />
        </Col>
      </Row>
    </>
  );
};

export default Sideheader;
