import React, { useState, useEffect } from 'react';
import Isotope from 'isotope-layout';
import '../key-concept/keyconcept.css';

// page
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
// bootstrap
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Collapse from 'react-bootstrap/Collapse';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { NavLink, useHistory } from 'react-router-dom';
// img
import pp_top from '../img/icon/Book_open_alt_light_b.png';
import pp_top_w from '../img/icon/Quiz-bank.png';
import img_1 from '../img/icon/Arrow_right_light.png';
import img_2 from '../img/question-img-1.png';
import img_3 from '../img/icon/Video_light.png';
import img_4 from '../img/book-img.png';
import img_5 from '../img/icon/Arrow_right_light_w.png';
import img_6 from '../img/paper-photo.png';
import img_7 from '../img/for.png';
import { useGetAllpastpaperdateQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import HTMLReactParser from 'html-react-parser';
import Pastpapercard from './Pastpapercard';
import { Link } from 'react-scroll';

const Unit = (props) => {
  const history = useHistory();

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };
  const pastpaper = useGetAllpastpaperdateQuery(props.match.params.slug);
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);

  const [iso, setIso] = useState(null);
  const Easy = 'Easy';
  const Medium = 'Medium';
  const Hard = 'Hard';

  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);

  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  if (pastpaper.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (pastpaper.data[0] == null)
    return (
      <div>

        <Header />
        <Error />
      </div>
    );
  return (
    <section>
      <div className="top-header-past-paper">
        <Header />
        <section className="ap">
          <Container>
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <NavLink to="/">Home</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={'/AP/' + pastpaper.data[0].curriculaslug}>
                        {pastpaper.data[0].curriculaname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={'/AP/' + pastpaper.data[0].curriculaslug}>
                        {pastpaper.data[0].subjname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {pastpaper.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading">
                  <h2>{pastpaper.data[0].couresname} - Past Paper</h2>
                  <Row>
                    <Col sm={8}>
                      <p>
                        {HTMLReactParser(pastpaper.data[0].couresdescription)}
                      </p>
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center">
                        <img
                          src={pp_top}
                          className="d"
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="unit-page pt-60 pb-120">
        <Container>
          {pastpaper.data.map((paperdate) => {
            return (
              <Row>
                <Col
                  sm={3}
                  className="desktop-view">
                  <div className="question-left">
                    <Link
                      to={paperdate.slug}
                      className="question-link">
                      {paperdate.name}
                    </Link>
                  </div>
                </Col>

                <Pastpapercard data={paperdate.slug} />
              </Row>
            );
          })}
        </Container>
      </section>
      <Modal
        className="Formula-popup"
        {...props}
        size="xl"
        centered
        show={showh}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header
          className="full-img-popup"
          closeButton></Modal.Header>
        <Modal.Body>
          <img src={img_7} />
        </Modal.Body>
      </Modal>
      <Footer />
    </section>
  );
};

export default Unit;
