import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useGetAllsubjectQuery } from '../../services/aplms';
import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';
import Nav from 'react-bootstrap/Nav';
import Mobileright from './Mobileright';

const Mobileleft = (props) => {
    const slug = props.propsintroData;
    const [openIndex, setOpenIndex] = useState(null);
    const couresdata = useGetAllsubjectQuery(slug);

    if (couresdata.isLoading)
        return (
            <div>
                <Loader />
            </div>
        );

    if (couresdata.data[0] == null)
        return (
            <div>
                <Error />
            </div>
        );

    if (couresdata.isError)
        return <Error />;

    const handleNavLinkClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            {couresdata.data.map((subject, index) => (
                <Accordion key={index} activeKey={openIndex === index ? '0' : ''}>
                    <Accordion.Item eventKey="0">
                        <Nav.Link eventKey={subject.slug} onClick={() => handleNavLinkClick(index)}>
                            <Accordion.Header>
                                {subject.name}
                            </Accordion.Header>
                        </Nav.Link>
                        <Accordion.Body>
                            <Mobileright propsData={subject.slug} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ))}
        </>
    );
};

export default Mobileleft;
