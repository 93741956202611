import React from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  useLogoutUserMutation,
  useGetLoggedUserQuery,
} from '../../services/aplms';
import {
  getToken,
  removeToken,
  removeUserinfo,
} from '../../services/LocalStorageService';
import { useEffect, useState } from 'react';
import { setUserInfo, unsetUserInfo } from '../../features/userSlice';
import { unsetUserToken } from '../../features/authSlice';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Logout = () => {
  const [userData, setUserData] = useState({
    email: '',
    name: '',
  });

  const history = useHistory();
  const token = getToken();
  const [logoutUser] = useLogoutUserMutation();
  const { data, isSuccess } = useGetLoggedUserQuery(token);

  const handleLogout = async () => {
    const res = await logoutUser({ token });
    if (res.data.status === 'success') {
      dispatch(unsetUserToken({ token: null }));
      dispatch(unsetUserInfo({ email: '', name: '' }));
      removeToken('token');
      removeUserinfo('userinfo');
      history.push('/');
    }
  };
  // Store User Data in Local State
  useEffect(() => {
    if (data && isSuccess) {
      setUserData({
        email: data.user.email,
        name: data.user.name,
      });
    }
  }, [data, isSuccess]);

  // Store User Data in Redux Store
  const dispatch = useDispatch();
  useEffect(() => {
    if (data && isSuccess) {
      dispatch(
        setUserInfo({
          email: data.user.email,
          name: data.user.name,
        })
      );
    }
  }, [data, isSuccess, dispatch]);
  return (
    <>
      <NavLink
        to="/"
        onClick={handleLogout}>
        Logout
      </NavLink>
    </>
  );
};

export default Logout;
