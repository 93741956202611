import React, { useState } from 'react';
import EasyIcon from "../img/icon/EasyIcon.png";
import MediumIcon from "../img/icon/MediumIcon.png";
import HardIcon from "../img/icon/HardIcon.png";

const Filter = ({ filterValue, setFilterValue }) => {
  const [activeItem, setActiveItem] = useState('All');

  return (
    <ul className="filter_question_top">
      <button
        onClick={() => {
          setFilterValue('Easy');
          setActiveItem('Easy');
        }}
        className={activeItem === 'Easy' ? 'active' : ''}
      >
        <img
          src={EasyIcon}
          alt="Easy Icon"
          style={{ width: "28px", height: "28px", marginRight: "8px", marginTop: "2px" }}  // Increased size
        />
        Easy
      </button>

      <button
        onClick={() => {
          setFilterValue('Medium');
          setActiveItem('Medium');
        }}
        className={activeItem === 'Medium' ? 'active' : ''}
      >
        <img
          src={MediumIcon}
          alt="Medium Icon"
          style={{ width: "28px", height: "28px", marginRight: "8px", marginTop: "2px" }}  // Increased size
        />
        Medium
      </button>

      <button
        onClick={() => {
          setFilterValue('Hard');
          setActiveItem('Hard');
        }}
        className={activeItem === 'Hard' ? 'active' : ''}
      >
        <img
          src={HardIcon}
          alt="Hard Icon"
          style={{ width: "28px", height: "28px", marginRight: "8px", marginTop: "2px" }} // Increased size
        />
        Hard
      </button>

      <button
        onClick={() => {
          setFilterValue('All');
          setActiveItem('All');
        }}
        className={activeItem === 'All' ? 'active' : ''}
      >
        All
      </button>
    </ul>
  );
};

export default Filter;
