import React, { useState } from 'react';

const Filter = ({ filterValue, setFilterValue }) => {
  const [activeItem, setActiveItem] = useState('Profile');

  const handleButtonClick = (value) => {
    setActiveItem(value);
    setFilterValue(value);
  };

  return (
    <ul>
      <button
        onClick={() => handleButtonClick('Profile')}
        className={activeItem === 'Profile' ? 'active' : ''}>
        My Profile
      </button>
      <button
        onClick={() => handleButtonClick('Password')}
        className={activeItem === 'Password' ? 'active' : ''}>
        Password
      </button>
      <button
        onClick={() => handleButtonClick('Billing')}
        className={activeItem === 'Billing' ? 'active' : ''}>
        Billing
      </button>
      <button
        onClick={() => handleButtonClick('Notifications')}
        className={activeItem === 'Notifications' ? 'active' : ''}>
        Notifications
      </button>
    </ul>
  );
};

export default Filter;
