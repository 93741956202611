import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import img11 from '../img/comingsoon.png';
import img12 from '../img/404-mobile.png';
import { Link, useHistory } from 'react-router-dom';

import './error.css';

const Privacypolicy = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <>
      <div className="pagenotfoundheader">{/* <Header /> */}</div>
      <section className="Pagenotfound">
        <div className="background-img-notfound desktop-view">
          <img src={img11} />
        </div>
        <div className="background-img-notfound mobile-view">
          <img src={img11} />
        </div>
        <Container>
          <Row>
            <Col
              sm={4}
              style={{ display: 'flex', alignItems: 'center' }}>
              <div className="page-not-found-content">
                <h2>Coming Soon</h2>
                <Link
                  to="/"
                  className="page-not-found-to-go-back"
                  onClick={goBack}>
                  Go Back
                </Link>
              </div>
            </Col>
            <Col sm={8}></Col>
          </Row>
        </Container>
      </section>
      <div>{/* <Footer /> */}</div>
    </>
  );
};

export default Privacypolicy;
