import React, { useState } from 'react';

const Filter = ({ filterValue, setFilterValue }) => {
  const [activeItem, setActiveItem] = useState('All');

  return (
    <ul>
      <button
        onClick={() => {
          setFilterValue('Easy');
          setActiveItem('Easy');
        }}
        className={activeItem === 'Easy' ? 'active' : ''}>
        Easy
      </button>
      <button
        onClick={() => {
          setFilterValue('Medium');
          setActiveItem('Medium');
        }}
        className={activeItem === 'Medium' ? 'active' : ''}>
        Medium
      </button>
      <button
        onClick={() => {
          setFilterValue('Hard');
          setActiveItem('Hard');
        }}
        className={activeItem === 'Hard' ? 'active' : ''}>
        Hard
      </button>
      <button
        onClick={() => {
          setFilterValue('All');
          setActiveItem('All');
        }}
        className={activeItem === 'All' ? 'active' : ''}>
        All
      </button>
    </ul>
  );
};

export default Filter;
