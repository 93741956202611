import React from 'react';
import '../key-concept/keyconcept.css';
import { NavLink, useHistory } from 'react-router-dom';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Dropdown from 'react-bootstrap/Dropdown';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Key from '../img/icon/Molecule_light.png';
import Question from '../img/icon/Quiz.png';
import Past from '../img/icon/Book_open_alt_light.png';
import Quiz from '../img/icon/Book.png';
import Key_w from '../img/icon/Molecule_light_w.png';
import Question_w from '../img/icon/Quiz_w.png';
import Past_w from '../img/icon/Book_open_alt_light_w.png';
import Quiz_w from '../img/icon/Book_w.png';
import qu_top from '../img/icon/Quiz-bank-b.png';
import qu_top_w from '../img/icon/Quiz-bank.png';
import Notebook_light from '../img/icon/notebook_light.png';
import Right_arror from '../img/icon/right-arror.png';
import Right_arror_w from '../img/icon/Expand_right_light_w.png';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import { useGetAllunitQuery } from '../services/aplms';
import HTMLReactParser from 'html-react-parser';
import { Link } from 'react-scroll';
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image

const Key_concept = (props) => {
  const history = useHistory();

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };
  const unitdata = useGetAllunitQuery(props.match.params.slug);
  if (unitdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (unitdata.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (unitdata.isError) return <h1>An error occured {unitdata.error.error}</h1>;
  return (
    <section className='quiz-section'>
      <div className="top-header-quiz">
        <Header />
        <section className="ap">
          <Container>
          <img
            src={BGRight1}
            alt="Splatter"
            className="splatter-image-right-1"
          />
          <img
            src={BGRight2}
            alt="Splatter"
            className="splatter-image-right-2"
          />
          <img
            src={BGMiddle}
            alt="Splatter"
            className="splatter-image-middle"
          />
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <NavLink to="/">Home</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={'/AP/' + unitdata.data[0].curriculaslug}>
                        {unitdata.data[0].curriculaname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={'/AP/' + unitdata.data[0].curriculaslug}>
                        {unitdata.data[0].subjname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {unitdata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>

                    <Breadcrumb.Item active>Quiz</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading">
                  <h2> {unitdata.data[0].couresname}- Quiz</h2>
                  <Row>
                    <Col sm={8}>
                      <p>
                        {HTMLReactParser(unitdata.data[0].couresdescription)}
                      </p>
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center">
                        <img
                          src={qu_top}
                          className="d"
                        />
                        <img src={qu_top_w} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Dropdown>
                  <Dropdown.Toggle
                    className="unit-button"
                    id="dropdown-basic">
                    <span>
                      <img src={Notebook_light} />
                      Jump to
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="unit-menu">
                    {unitdata.data.map((unit) => {
                      return (
                        <Dropdown.Item to={unit.unitnumber}>
                          <Link to={unit.unitnumber}>
                            Unit {unit.unitnumber}
                          </Link>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="ap-main-content">
        <Container className="mb-120">
          <Row>
            {unitdata.data.map((unit) => {
              return (
                <Col
                  sm={4}
                  id={unit.unitnumber}>
                  <NavLink
                    to={'/Quiz-Unit/' + unit.slug}
                    className="quiz-box">
                    <h4 className="top-title">
                      Unit {unit.unitnumber} : {unit.name}
                    </h4>
                    <div className="right-arror-key">
                      <img
                        src={Right_arror}
                        className="d"
                      />
                      <img src={Right_arror_w} />
                    </div>
                  </NavLink>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      <Footer />
    </section>
  );
};

export default Key_concept;
