import React, { useEffect, useRef, useState } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Forgot from "./Forgot";
import Header from "../layout/header/Header";
import logo_d from "../img/logo/black-logo.png";
import logo_m_d from "../img/icon/mobile-view-logo.png";
import logo_w from "../img/logo/white-logo.png";
import img_1 from "../img/icon/Social g.png";
import img_2 from "../img/icon/Social l.png";
import img_3 from "../img/icon/Social f.png";
import { getToken, storeToken } from "../services/LocalStorageService";
import { setUserToken } from "../features/authSlice";
import { useDispatch } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
import { useLoginUserMutation } from "../services/aplms";
import Footer from "./Footer";

const Login = () => {
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const history = useHistory();
  const [loginUser] = useLoginUserMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get("email"),
      password: data.get("password"),
    };
    if (actualData.email && actualData.password) {
      const res = await loginUser(actualData);
      console.log(res);
      if (res.data && res.data.status === true) {
        // storeToken(res.data.token);
        localStorage.setItem("token", res.data.token);

        history.push("/My-Profile");
      }
      if (res.error && res.error.data.status === "failed") {
        setError({ status: true, msg: res.error.data.message, type: "error" });
      }
    } else {
      setError({ status: true, msg: "All Fields are Required", type: "error" });
    }
  };

  return (
    <>
      <section className="login pt-60 desktop-view">
        <Container>
          <Row className="pb-60">
            <Col sm={12} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={logo_w} className="drack" />
                </Link>
                <Link to="/">
                  <img src={logo_d} className="lite" />
                </Link>
              </div>
              <div className="login-from">
                {error.status ? (
                  <p className="p_alrat" severity={error.type}>
                    {error.msg}
                  </p>
                ) : (
                  ""
                )}

                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      id="email"
                      name="email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <div className="password">
                      <Form.Label>Password</Form.Label>
                      <Link to="/forgot" className="login-button-style">
                        Forgot Password?
                      </Link>
                    </div>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                    />
                  </Form.Group>
                  <Form.Group className="mb-5" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                  <p className="from-bottom-text">
                    By continuing, you accept our <span>Terms of Use</span>, our{" "}
                    <span>Privacy Policy</span> and that your data is stored in
                    the cloud.
                  </p>
                </Form>
              </div>
              <div className="signupbutton_loginpage">
                <NavLink to="/sinup" variant="primary" type="submit">
                  Create new account
                </NavLink>
              </div>
              <p className="Continue-text">Or Continue With...</p>
              <Row>
                <Col sm={4}>
                  <div className="login-button">
                    <Button variant="primary" type="submit">
                      <img src={img_1} /> Google
                    </Button>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="login-button">
                    <Button variant="primary" type="submit">
                      <img src={img_2} /> LinkedIn
                    </Button>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="login-button">
                    <Button variant="primary" type="submit">
                      <img src={img_3} /> Facebook
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>
      {/* Mobile view start */}
      <section className="login mobile-view">
        <Header />
        <Container>
          <Row>
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={logo_m_d} className="drack" />
                </Link>
                <Link to="/">
                  <img src={logo_m_d} className="lite-logo" />
                </Link>
              </div>
              <h4 className="signup-page-mobile-view-top-header mt-30 mb-30">
                Discover. Dive. Dominate.
              </h4>
            </Col>
            <Col sm={6}>
              <div className="login-from">
                {error.status ? (
                  <p className="p_alrat" severity={error.type}>
                    {error.msg}
                  </p>
                ) : (
                  ""
                )}
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      id="email"
                      name="email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      id="password"
                      name="password"
                    />
                    <div className="password">
                      <Link to="/forgot" className="login-button-style">
                        Forgot Password?
                      </Link>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-5" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember me" />
                  </Form.Group>
                  <Row>
                    <Col sm={12}>
                      <p className="or-login-text">or Login with</p>
                    </Col>
                    <div className="or-login-button">
                      <div className="login-button">
                        <Button variant="primary" type="submit">
                          <img src={img_1} /> Google
                        </Button>
                      </div>
                      <div className="login-button">
                        <Button variant="primary" type="submit">
                          <img src={img_2} /> LinkedIn
                        </Button>
                      </div>
                      <div className="login-button">
                        <Button variant="primary" type="submit">
                          <img src={img_3} /> Facebook
                        </Button>
                      </div>
                    </div>
                  </Row>
                  <Button variant="primary" type="submit">
                    Login
                  </Button>
                  <p className="from-bottom-text">
                    By continuing, you accept our <span>Terms of Use</span>, our{" "}
                    <span>Privacy Policy</span> and that your data is stored in
                    the cloud.
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
