import React from 'react';
import './contactus.css';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import { Col, Row, Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import img_1 from '../img/mitchkoko_a_cute_robot.png';

const Contactus = () => {
  return (
    <>
      <section className="contactus-page desktop-view">
        <Header />
        <div className="contactus-page-content">
          <Container>
            <Row>
              <Col sm={6}>
                <div className="contactus-page-left">
                  <h2 className="mb-30">
                    Contact <span>us</span>
                  </h2>
                  <div className="content-page-from">
                    <Form>
                      <Form.Group
                        className="mb-60"
                        controlId="formBasicName">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-60"
                        controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-30"
                        controlId="exampleForm.ControlTextarea1">
                        <div className="textarea-input">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Message"
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-30"
                        controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="I would like to receive the news updates."
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit">
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
              <Col sm={6}>
                <div className="contactus-page-right-box">
                  <img src={img_1} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </section>
      <section className="contactus-page mobile-view">
        <Header />
        <div className="contactus-page-content">
          <Container>
            <Row className="mb-60">
              <Col sm={6}>
                <div className="contactus-page-right-box">
                  <img src={img_1} />
                </div>
              </Col>
              <Col sm={6}>
                <div className="contactus-page-left">
                  <h2 className="mb-30 mt-30">
                    Contact <span>us</span>
                  </h2>
                  <div className="content-page-from">
                    <Form className="mobile-view-from-content-page">
                      <Form.Group
                        className="mb-60"
                        controlId="formBasicName">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-60"
                        controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-30"
                        controlId="exampleForm.ControlTextarea1">
                        <div className="textarea-input">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Message"
                          />
                        </div>
                      </Form.Group>
                      <Form.Group
                        className="mb-30"
                        controlId="formBasicCheckbox">
                        <Form.Check
                          type="checkbox"
                          label="I would like to receive the news updates."
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit">
                        Submit
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Contactus;
