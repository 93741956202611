import React from 'react';
import "./search.css";
import { SearchBar } from "./components/searchBar";
import styled from "styled-components";

const AppContainer = styled.div`
      height: 10px;
`;

const Search = () => {
  return (
    <>

      <AppContainer>
        <SearchBar />
      </AppContainer>

    </>
  )
}

export default Search