import React, { useState, useEffect } from 'react';
import Isotope from 'isotope-layout';
import Filter from './Filter';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-scroll';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import img_1 from '../img/paper-photo.png';
import img_3 from '../img/for.png';
import img_2 from '../img/icon/Full_Screen_Corner_Light.png';
import { useGetAllpastpaperquestionQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import Popupphoto from '../common/photopopup/Popupphoto';
import Popupvideo from '../common/videopopup/Popupvideo';
import HTMLReactParser from 'html-react-parser';
import Filepop from '../common/file/Filepop';
import { NavLink, useHistory } from 'react-router-dom';

const View = ({
  blogs,
  handleBlogs,
  filterValue,
  setFilterValue,
  props,
  propsData,
}) => {
  const [model, setModel] = useState(false);
  const [tempdata, setTempdata] = useState();
  const getData = (photo) => {
    let tempData = [photo];
    setTempdata((catdata) => [1, ...tempData]);
    return setModel(true);
  };
  const [videomodel, setvideoModel] = useState(false);
  const [videodata, setVideodata] = useState();
  const getvideoData = (video) => {
    let videoData = [video];
    setVideodata((catdata) => [1, ...videoData]);
    return setvideoModel(true);
  };

  const [filemodel, setfileModel] = useState(false);
  const [filedata, setFiledata] = useState();
  const getfileData = (file) => {
    let fileData = [file];
    setFiledata((catdata) => [1, ...fileData]);
    return setfileModel(true);
  };

  const history = useHistory();

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };

  const questiondata = useGetAllpastpaperquestionQuery(propsData);

  const [iso, setIso] = useState();

  useEffect(() => {
    setIso(
      new Isotope('.grid', {
        itemSelector: '.filter-item',
        layoutMode: 'fitRows',
        filter: '.All',
      })
    );
  }, [questiondata.data]);

  // handling filter key change
  useEffect(() => {
    if (iso) {
      filterValue === 'All'
        ? iso.arrange({ filter: '*' })
        : iso.arrange({ filter: `.${filterValue}` });
    }
  }, [filterValue]);

  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);

  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const fullimageClose = () => setShow(false);
  const fullimageShow = () => setShow(true);

  if (questiondata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (questiondata.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (questiondata.isError)
    return <h1>An error occured {questiondata.error.error}</h1>;

  console.log(questiondata.data);

  return (
    <div className="App">
      <>
        <div className="top-header-past-paper">
          <Header />
          <section className="ap">
            <Container>
              <Row className="pb-120">
                <Col sm={12}>
                  <div className="top-nav">
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <NavLink to="/">Home</NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <NavLink
                          to={'/AP/' + questiondata.data[0].curriculaslug}>
                          {questiondata.data[0].curriculaname}
                        </NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <NavLink
                          to={'/AP/' + questiondata.data[0].curriculaslug}>
                          {questiondata.data[0].subjname}
                        </NavLink>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <span onClick={() => goBackStepByStep(1)}>
                          {questiondata.data[0].couresname}
                        </span>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item href="#">Past Paper</Breadcrumb.Item>
                    </Breadcrumb>
                  </div>
                </Col>
                <Col sm={12}>
                  <div className="ap-heading pt-100">
                    <h2>
                      {questiondata.data[0].aptopicname}
                      <span> {questiondata.data[0].papername}</span>
                    </h2>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Container>
            <Row>
              <Col sm={3}></Col>
              <Col sm={9}>
                <div className="filter_question_top">
                  <Filter setFilterValue={setFilterValue} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="question-main-box pt-60 pb-60">
          <Container>
            <Row>
              <Col sm={3}>
                <div className="question-left desktop-view">
                  {questiondata.data.map((catdata) => {
                    return (
                      <Link
                        to={catdata.questionnumber}
                        className="question-link">
                        Question {catdata.questionnumber}
                      </Link>
                    );
                  })}
                </div>
                <div className="mobile-view overflow-x">
                  <div className="question-left">
                    {questiondata.data.map((catdata) => {
                      return (
                        <Link
                          to={catdata.questionnumber}
                          className="question-link">
                          {catdata.questionnumber}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col sm={9}>
                <div className="grid">
                  {questiondata.data.map((catdata) => {
                    return (
                      <div
                        className={`filter-item  ${catdata.status} All`}
                        id={catdata.questionnumber}>
                        <Container>
                          <Row className="question-main-box-style past-paper">
                            <Col sm={12}>
                              <div className="question-filter-top-heading">
                                <h4>Question {catdata.questionnumber}</h4>
                                <Row className="question-filter-top-heading-center">
                                  <Col sm={6}>
                                    <ProgressBar>
                                      <ProgressBar
                                        now={catdata.status === 'Easy' ? 30 : catdata.status === 'Medium' ? 60 : catdata.status === 'Hard' ? 90 : 0}
                                        label={catdata.status}
                                        variant={catdata.status}
                                        key={1}
                                      />
                                    </ProgressBar>
                                  </Col>
                                  <Col
                                    sm={6}
                                    className="question-filter-top-heading-right">
                                    <Button
                                      className="desktop-view"
                                      variant="popup-button-1"
                                      onClick={() =>
                                        getfileData(catdata.formula)
                                      }>
                                      Formulas
                                    </Button>
                                  </Col>
                                </Row>
                                <div className="question-paper-photo">
                                  <Button
                                    variant="popup-button-full-img"
                                    onClick={() =>
                                      getData(catdata.description)
                                    }>
                                    <img src={img_2} />
                                  </Button>
                                  <p className="popup-img-img">
                                    {HTMLReactParser(catdata.description)}
                                  </p>
                                </div>
                                <div className="popup-button-question-page mt-30">
                                  <Button
                                    className="mobile-view"
                                    variant="popup-button-1"
                                    onClick={() =>
                                      getfileData(catdata.formula)
                                    }>
                                    Formulas
                                  </Button>
                                  <Button
                                    variant="popup-button-2"
                                    onClick={() => getData(catdata.photo)}>
                                    Mark Scheme
                                  </Button>

                                  {catdata.video.split(',').map((video) => {
                                    return (
                                      <Button
                                        variant="popup-button-3"
                                        onClick={() => getvideoData(video)}>
                                        Video Answer
                                      </Button>
                                    );
                                  })}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {model === true ? (
          <Popupphoto
            photo={tempdata[1]}
            hide={() => setModel(false)}
          />
        ) : (
          ''
        )}
        {videomodel === true ? (
          <Popupvideo
            video={videodata[1]}
            hide={() => setvideoModel(false)}
          />
        ) : (
          ''
        )}

        {filemodel === true ? (
          <Filepop
            file={filedata[1]}
            hide={() => setfileModel(false)}
          />
        ) : (
          ''
        )}
      </>
    </div>
  );
};
export default View;
