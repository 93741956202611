import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Table, Dropdown, Button } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import axios from 'axios';
import Pastpapercouser from './Pastpapercouser';

const Pastpaperexamcontent = () => {
    const [curriculum, setCurriculum] = useState([]);
    const [courses, setCourses] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const [papers, setPapers] = useState([]);
    const [years, setYears] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [zones, setZones] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [filteredQuestions, setFilteredQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [questionsPerPage] = useState(10);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showQuestion, setShowQuestion] = useState(true);
    const [activeTab, setActiveTab] = useState('question');
    const [selectedCurriculum, setSelectedCurriculum] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedPaper, setSelectedPaper] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedSeason, setSelectedSeason] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [curriculumResponse, questionResponse] = await Promise.all([
                    axios.get('https://num8ers.cloud/api/pastpapercurriculums'),
                    axios.get('https://num8ers.cloud/api/pastpaperselectquestion'),
                ]);

                setCurriculum(curriculumResponse.data);
                setQuestions(questionResponse.data);
                setFilteredQuestions(questionResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const fetchCourses = async (curriculumId) => {
        try {
            const response = await axios.get(`https://num8ers.cloud/api/pastpapercourses/${curriculumId}`);
            setCourses(response.data);
        } catch (error) {
            console.error('Error fetching courses:', error);
        }
    };

    const fetchSubjects = async (courseId) => {
        try {
            const response = await axios.get(`https://num8ers.cloud/api/pastpapersubjects/${courseId}`);
            setSubjects(response.data);
        } catch (error) {
            console.error('Error fetching subjects:', error);
        }
    };

    const fetchPapers = async (subjectId) => {
        try {
            const response = await axios.get(`https://num8ers.cloud/api/pastpaperselectpaper/${subjectId}`);
            setPapers(response.data);
        } catch (error) {
            console.error('Error fetching papers:', error);
        }
    };

    const fetchYears = async (paperId) => {
        try {
            const response = await axios.get(`https://num8ers.cloud/api/pastpaperselectyear/${paperId}`);
            setYears(response.data);
        } catch (error) {
            console.error('Error fetching years:', error);
        }
    };

    const fetchSeasons = async (yearId) => {
        try {
            const response = await axios.get(`https://num8ers.cloud/api/pastpaperselectseason/${yearId}`);
            setSeasons(response.data);
        } catch (error) {
            console.error('Error fetching seasons:', error);
        }
    };

    const fetchZones = async (seasonId) => {
        try {
            const response = await axios.get(`https://num8ers.cloud/api/pastpaperselectzone/${seasonId}`);
            setZones(response.data);
        } catch (error) {
            console.error('Error fetching zones:', error);
        }
    };

    const handleFilterChange = (type, value) => {
        let filtered = questions;

        if (type === 'curriculum') {
            setSelectedCurriculum(value);
            fetchCourses(value);
            setSelectedCourse(null);
            setSelectedSubject(null);
            setSelectedPaper(null);
            setSelectedYear(null);
            setSelectedSeason(null);
            setSelectedZone(null);
            filtered = filtered.filter(q => q.currms_id === value);
        } else if (type === 'course') {
            setSelectedCourse(value);
            fetchSubjects(value);
            setSelectedSubject(null);
            setSelectedPaper(null);
            setSelectedYear(null);
            setSelectedSeason(null);
            setSelectedZone(null);
            filtered = filtered.filter(q => q.courses_id === value);
        } else if (type === 'subject') {
            setSelectedSubject(value);
            fetchPapers(value);
            setSelectedPaper(null);
            setSelectedYear(null);
            setSelectedSeason(null);
            setSelectedZone(null);
            filtered = filtered.filter(q => q.subjs_id === value);
        } else if (type === 'paper') {
            setSelectedPaper(value);
            fetchYears(value);
            setSelectedYear(null);
            setSelectedSeason(null);
            setSelectedZone(null);
            filtered = filtered.filter(q => q.papers_id === value);
        } else if (type === 'year') {
            setSelectedYear(value);
            fetchSeasons(value);
            setSelectedSeason(null);
            setSelectedZone(null);
            filtered = filtered.filter(q => q.years_id === value);
        } else if (type === 'season') {
            setSelectedSeason(value);
            fetchZones(value);
            setSelectedZone(null);
            filtered = filtered.filter(q => q.season_id === value);
        } else if (type === 'zone') {
            setSelectedZone(value);
            filtered = filtered.filter(q => q.zone_id === value);
        }

        setFilteredQuestions(filtered);
        setCurrentPage(1);
        setCurrentQuestionIndex(0);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleQuestionNavigation = (direction) => {
        if (direction === 'next' && currentQuestionIndex < filteredQuestions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else if (direction === 'previous' && currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
        setShowQuestion(true);
        setShowAnswer(false);
    };

    const toggleContent = (content) => {
        if (content === 'question') {
            setShowQuestion(true);
            setShowAnswer(false);
        } else if (content === 'answer') {
            setShowQuestion(false);
            setShowAnswer(true);
        }
        setActiveTab(content);
    };

    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = filteredQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);
    const currentQuestion = filteredQuestions[currentQuestionIndex];

    return (
        <>
            <section className='Pastpaperexamcontent-page'>
                <Container>
                    <div className='Pastpaperexamcontent-page-filter'>
                        <Form>
                            <Row className='desktop-view'>

                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Curriculum:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-curriculum">
                                            {selectedCurriculum ? curriculum.find(curriculum => curriculum.id === selectedCurriculum)?.name : 'Select Curriculum'}

                                            {selectedCourse ? courses.find(courses => courses.id === selectedCourse)?.name : 'Select Course'}

                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className='filter-drop-menu'>
                                                <div className='filter-drop-menu-heading'>
                                                    {curriculum.map(cur => (
                                                        <div key={cur.id} className='filter-drop-filter-main-box-style'>
                                                            <h5 onClick={() => handleFilterChange('curriculum', cur.id)}>{cur.name}</h5>

                                                            <Pastpapercouser curriculumId={cur.id} handleFilterChange={handleFilterChange} />

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>

                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Subject:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-subject">
                                            {selectedSubject ? subjects.find(subject => subject.id === selectedSubject)?.name : 'Select Subject'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {subjects.map(subject => (
                                                <Dropdown.Item key={subject.id} onClick={() => handleFilterChange('subject', subject.id)}>
                                                    {subject.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>

                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Paper:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-paper">
                                            {selectedPaper ? papers.find(paper => paper.id === selectedPaper)?.name : 'Select Paper'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {papers.map(paper => (
                                                <Dropdown.Item key={paper.id} onClick={() => handleFilterChange('paper', paper.id)}>
                                                    {paper.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Year:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-year">
                                            {selectedYear ? years.find(year => year.id === selectedYear)?.name : 'Select Year'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {years.map(year => (
                                                <Dropdown.Item key={year.id} onClick={() => handleFilterChange('year', year.id)}>
                                                    {year.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Season:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-season">
                                            {selectedSeason ? seasons.find(season => season.id === selectedSeason)?.name : 'Select Season'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {seasons.map(season => (
                                                <Dropdown.Item key={season.id} onClick={() => handleFilterChange('season', season.id)}>
                                                    {season.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Zone:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-zone">
                                            {selectedZone ? zones.find(zone => zone.id === selectedZone)?.name : 'Select Zone'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {zones.map(zone => (
                                                <Dropdown.Item key={zone.id} onClick={() => handleFilterChange('zone', zone.id)}>
                                                    {zone.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className='Pastpaperexamcontent-page-filter-content desktop-view'>
                        <Row>
                            <Col sm={3}>
                                <div className='Pastpaperexamcontent-page-filter-content-left'>
                                    <div className='Pastpaperexamcontent-page-filter-content-left-header'>
                                        <div className='Pastpaperexamcontent-page-filter-content-left-header-content'>
                                            <p>Asc</p>
                                            <p><span>Sort By:</span></p>
                                            <p>Desc</p>
                                        </div>
                                    </div>
                                    <div className='Pastpaperexamcontent-page-filter-content-left-content'>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>Question No.</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentQuestions.map((question, index) => (
                                                    <tr key={question.id} onClick={() => setCurrentQuestionIndex(indexOfFirstQuestion + index)}>
                                                        <td>NUM8ERS Question {indexOfFirstQuestion + index + 1}</td> {/* Adding 1 to make it 1-based index */}

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <div className='Pagination-bar'>
                                        <Pagination>
                                            {Array.from({ length: Math.ceil(filteredQuestions.length / questionsPerPage) }, (_, index) => (
                                                <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => handlePageChange(index + 1)}>
                                                    {index + 1}
                                                </Pagination.Item>
                                            ))}
                                        </Pagination>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={9}>
                                <div className='Pastpaperexamcontent-page-filter-content-right'>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-header'>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-header-content'>
                                            <p onClick={() => console.log('Share')}>Share</p>
                                            <p onClick={() => { handleQuestionNavigation('previous'); setActiveTab('question'); }}>Previous</p>
                                            <p onClick={() => { handleQuestionNavigation('next'); setActiveTab('question'); }}>Next</p>
                                            <p onClick={() => toggleContent('question')} className={activeTab === 'question' ? 'active' : ''}><span>Question</span></p>
                                            <p onClick={() => toggleContent('answer')} className={activeTab === 'answer' ? 'active' : ''}>Answer</p>
                                        </div>
                                    </div>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-content'>
                                        {currentQuestion && (
                                            <>
                                                <div className='Pastpaperexamcontent-page-filter-content-right-content-header'>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <p className='topic-color'>Topic(s):</p>
                                                        </Col>
                                                        <Col sm={9}>
                                                            <p>{currentQuestion.name}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {showQuestion && (
                                                    <div className='question'>

                                                        <object data={currentQuestion.description + "#toolbar=0"} className='object_pdf_question' toolbar="0"></object>
                                                    </div>
                                                )}
                                                {showAnswer && (
                                                    <div className='answer'>

                                                        <object data={currentQuestion.answer + "#toolbar=0"} className='object_pdf_question' toolbar="0"></object>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default Pastpaperexamcontent;








{/* <div className='Pastpaperexamcontent-page-filter-content mobile-view'>
                        <Row>

                            <Col sm={3}>
                                <div className='mobile-view Pastpaperexamcontent-bottom-margen-mobile-view'>
                                    <div className='Pastpaperexamcontent-page-filter-content-left'>
                                        <div className='Pastpaperexamcontent-page-filter-content-left-content'>
                                            <ul className='Pastpaperexamcontent-mobile-view'>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                            </ul>
                                            <div className='Pagination-bar mobile-view-Pagination-bar'>
                                                <Pagination>
                                                    <Pagination.Prev />
                                                    <Pagination.Item active>{1}</Pagination.Item>
                                                    <Pagination.Item>{2}</Pagination.Item>
                                                    <Pagination.Item>{3}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item>{32}</Pagination.Item>
                                                    <Pagination.Item>{33}</Pagination.Item>
                                                    <Pagination.Next />
                                                </Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} className='Pastpaperexamcontent-top-content-1 '>
                                <div className='Pastpaperexamcontent-page-filter-content-right-header'>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-header-content'>
                                        <p>Share</p>
                                        <p>Previous</p>
                                        <p>Next</p>
                                        <p><span>Question</span></p>
                                        <p>Answer</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={9} className='Pastpaperexamcontent-top-content-2'>
                                <div className='Pastpaperexamcontent-page-filter-content-right'>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-header'>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-header-content'>
                                            <p className='topic-color'>Topic(s):</p>
                                            <p>Multiplication And Division</p>
                                        </div>
                                    </div>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-content'>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-content-img'>
                                            <p>
                                                <img src={questionimg} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>



                    </div> */}