import React, { useContext, useState, useEffect } from 'react';
import Isotope from 'isotope-layout';
import '../key-concept/keyconcept.css';
import { Link } from 'react-router-dom';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import qu_top from '../img/icon/Quiz-bank-b.png';
import qu_top_w from '../img/icon/Quiz-bank.png';
import chack from '../img/icon/chack.png';
import chack_wrong from '../img/icon/wrong.png';
import result_bottom from '../img/icon/result_bottom.png';
import { QuizContext } from './Context/QuizHolder';
import { Redirect } from 'react-router-dom';

const Result = () => {
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);

  const [answerIndicators, setAnswerIndicators] = useState([]);
  useEffect(() => {
    const storedAnswerIndicators =
      JSON.parse(localStorage.getItem('answerIndicators')) || [];
    setAnswerIndicators(storedAnswerIndicators);
  }, []);

  const { quizzes } = useContext(QuizContext);



  const isQuizzesEmpty = quizzes.length === 0;
  if (isQuizzesEmpty) {
    return <Redirect to="/" />;
  }

  const correctAnswers = answerIndicators.filter(
    (indicator) => indicator === 1
  ).length;
  const wrongAnswers = answerIndicators.filter(
    (indicator) => indicator !== 1
  ).length;

  const marks = answerIndicators.filter(
    (indicator) => indicator === 1
  ).length;

  // Calculate the score percentage
  const scorePercentage = (correctAnswers / quizzes.questions.length) * 100;

  return (
    <section>
      <div className="top-header-quiz">
        <Header />
        <section className="ap">
          <Container>
            <Row className="pb-30">
              <Col sm={12}>
                <div className="ap-heading">
                  <Row className="quiz_row_page">
                    <Col
                      md={{ span: 6, offset: 3 }}
                      className="quiz_top_header">
                      <div className="quiz_top_header_top pb-60">
                        <p>Let’s Practice</p>
                        <h2> {quizzes.questions[0].couresname} </h2>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <div className="key-concept-top-right text-center">
                        <img
                          src={qu_top}
                          className="d"
                        />
                        <img src={qu_top_w} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="quiz_question_top">
                  <p>
                    Unit {quizzes.questions[0].apunitsnumber} :{' '}
                    {quizzes.questions[0].unitname}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="unit-page pt-60 pb-120">
        <Container>
          <Row>
            <Col sm={12}>
              <Row className="box-content-center">
                <Col sm={6}>
                  <div className="Question_result">
                    {quizzes.questions.map((unit, index) => {
                      return (
                        <div
                          className="result-box"
                          key={index}>
                          <p>Question {index + 1}</p>
                          <div
                            className={`result-chack ${answerIndicators[index] === 1 ? '' : 'red'
                              }`}>
                            <img
                              src={
                                answerIndicators[index] === 1
                                  ? chack
                                  : chack_wrong
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="result-right-box">
                    <div className="result-show">
                      <p>
                        All Ten Question of {quizzes.questions[0].couresname}
                      </p>
                    </div>
                    <div className="result-show-content">
                      <p>Total Correct Answer = {correctAnswers}</p>
                      <p>Total Wrong Answer = {wrongAnswers}</p>

                      <p>
                        Your Score Percentage is {scorePercentage.toFixed(2)}%
                      </p>
                    </div>
                    <div className="result-show-bottom">
                      <p>You Got {quizzes.result} NP </p>
                      <img src={result_bottom} />
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="display-flex">
                <div className="quiz_bottom_button">
                  <a
                    href={'/Quiz-Question/' + quizzes.questions[0].apunitsslug}
                    className="quiz-box">
                    Start Another Quiz
                  </a>
                </div>
                <div className="quiz_bottom_button">
                  <Link
                    to="/"
                    className="quiz-box">
                    Home
                  </Link>
                </div>
                <div className="quiz_bottom_right">
                  <p>Save time with our</p>
                  <Button
                    variant="popup-button-1"
                    onClick={handleShow}>
                    Keyboard Shortcuts
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        className="Keyboard-Shortcuts"
        size="lg"
        centered
        show={showh}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="pb-120">
          <h4 className="mb-30">Keyboard Shortcuts</h4>
          <div className="Keyboard-Shortcuts-box-content">
            <p>Submit Answer or Continue</p>
            <div className="Keyboard-Shortcuts-box-content-button">
              <p>ENTER</p>
            </div>
          </div>
          <div className="Keyboard-Shortcuts-box-content">
            <p>Select Multiple Choice Answer</p>
            <div className="Keyboard-Shortcuts-box-content-button">
              <p>a</p>
              <p>b</p>
              <p>c</p>
              <p>d</p>
            </div>
          </div>
          <div className="Keyboard-Shortcuts-box-content">
            <p>Reset Blanks Exercise </p>
            <div className="Keyboard-Shortcuts-box-content-button">
              <p>R</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Footer />
    </section>
  );
};

export default Result;
