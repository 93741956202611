import React, { useEffect, useContext, useState } from 'react';
import { useGetAllquizquctionQuery } from '../services/aplms';
import HTMLReactParser from 'html-react-parser';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import Box from './Box';
import './quiz.css';
import { Link } from 'react-router-dom';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


export default function Question(props) {


  const [current, setCurrent] = useState(0);


  if (!props) {
    return <Error />;
  }
  return (
    <>
      <Box
        current={current}
        next={setCurrent}
        props={props}
      />
    </>
  );
}
