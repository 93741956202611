import React, { useState } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import questionimg from '../img/svg/question.svg';
import Dropdown from 'react-bootstrap/Dropdown';

const initialData = [
    { id: 1, name: 'Apple', category: 'Fruit' },
    { id: 2, name: 'Banana', category: 'Fruit' },
    { id: 3, name: 'Carrot', category: 'Vegetable' },
    { id: 4, name: 'Orange', category: 'Fruit' },


];

// const categories = ['Fruit', 'Vegetable'];

const Pastpaperexamcontent_3 = () => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [filteredData, setFilteredData] = useState(initialData);

    const handleSelectChange = (e) => {
        setSelectedCategory(e.target.value);
        const filteredData = initialData.filter(item => item.category === e.target.value);
        setFilteredData(filteredData);
    };

    return (
        <>

            <section className='Pastpaperexamcontent-page'>
                <Container>
                    <div className='Pastpaperexamcontent-page-filter'>
                        <Form>
                            <Row className='desktop-view'>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Curriculum:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                            {selectedCategory || 'Cambridge IGCSE'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {/* {categories.map(category => (
                                                <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                    {category}
                                                </Dropdown.Item>
                                            ))} */}
                                            <div className='filter-drop-menu'>
                                                <div className='filter-drop-menu-heading'>
                                                    <h5>Cambridge</h5>
                                                    <div className='filter-drop-menu-p'>
                                                        <p>Checkpoint</p>
                                                        <p>IGCSE</p>
                                                        <p>O-LEVEL</p>
                                                        <p>A-LEVEL</p>
                                                        <p>PRE-U</p>
                                                    </div>
                                                </div>
                                                <div className='filter-drop-menu-heading'>
                                                    <h5>Edexcel</h5>
                                                    <div className='filter-drop-menu-p'>
                                                        <p>IGCSE</p>
                                                        <p>IGCSE (9-1)</p>
                                                        <p>A-LEVEL</p>
                                                    </div>
                                                </div>
                                                <div className='filter-drop-menu-heading'>
                                                    <h5>OCR</h5>
                                                    <div className='filter-drop-menu-p'>
                                                        <p>IGCSE (9-1)</p>
                                                        <p>AS</p>
                                                        <p>A-LEVEL</p>
                                                    </div>
                                                </div>
                                                <div className='filter-drop-menu-heading'>
                                                    <h5>AQA</h5>
                                                    <div className='filter-drop-menu-p'>
                                                        <p>IGCSE</p>
                                                        <p>AS</p>
                                                        <p>A-LEVEL</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Form.Label>Subject:</Form.Label>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                            {selectedCategory || 'Accounting 9-1 (0985)'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {/* {categories.map(category => (
                                                <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                    {category}
                                                </Dropdown.Item>
                                            ))} */}
                                            <div className='filter-drop-menu'>
                                                <div className='filter-drop-menu-heading'>
                                                    <ul>
                                                        <li>Accounting 9-1 (0985)</li>
                                                        <li>Accounting (0452)</li>
                                                        <li>Additional Mathematics (0606)</li>
                                                        <li>Afrikaans 1st Language (0512)</li>
                                                        <li>Afrikaans 2nd Language (0548)</li>
                                                        <li>Agriculture (0600)</li>
                                                        <li>American History (US)(0409)</li>
                                                        <li>Arabic - First Language 9-1(7108)</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                            {selectedCategory || 'Select Paper'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {/* {categories.map(category => (
                                                <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                    {category}
                                                </Dropdown.Item>
                                            ))} */}
                                            <div className='filter-drop-menu'>
                                                <div className='filter-drop-menu-heading'>
                                                    <ul>
                                                        <li>1</li>
                                                        <li>2</li>
                                                        <li>3</li>
                                                        <li>4</li>
                                                        <li>5</li>
                                                        <li>6</li>
                                                        <li>7</li>
                                                        <li>8</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                            {selectedCategory || 'Select Season'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {/* {categories.map(category => (
                                                <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                    {category}
                                                </Dropdown.Item>
                                            ))} */}
                                            <div className='filter-drop-menu'>
                                                <div className='filter-drop-menu-heading'>
                                                    <ul>
                                                        <li>2024</li>
                                                        <li>2023</li>
                                                        <li>2022</li>
                                                        <li>2021</li>
                                                        <li>2020</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                            {selectedCategory || 'Select Season'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {/* {categories.map(category => (
                                                <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                    {category}
                                                </Dropdown.Item>
                                            ))} */}
                                            <div className='filter-drop-menu'>
                                                <div className='filter-drop-menu-heading'>
                                                    <ul>
                                                        <li>Summer</li>
                                                        <li>Winter</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                                <Col sm={2}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                            {selectedCategory || 'Select Zone'}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {/* {categories.map(category => (
                                                <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                    {category}
                                                </Dropdown.Item>
                                            ))} */}
                                            <div className='filter-drop-menu'>
                                                <div className='filter-drop-menu-heading'>
                                                    <ul>
                                                        <li>Zone 1</li>
                                                        <li>Zone 2</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            </Row>
                            <div className='mobile-view filtter-top-menu-style-mobile-view'>
                                <Dropdown>
                                    <Form.Label>Curriculum:</Form.Label>
                                    <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                        {selectedCategory || 'Cambridge IGCSE'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* {categories.map(category => (
                                            <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                {category}
                                            </Dropdown.Item>
                                        ))} */}
                                        <div className='filter-drop-menu'>
                                            <div className='filter-drop-menu-heading'>
                                                <h5>Cambridge</h5>
                                                <div className='filter-drop-menu-p'>
                                                    <p>Checkpoint</p>
                                                    <p>IGCSE</p>
                                                    <p>O-LEVEL</p>
                                                    <p>A-LEVEL</p>
                                                    <p>PRE-U</p>
                                                </div>
                                            </div>
                                            <div className='filter-drop-menu-heading'>
                                                <h5>Edexcel</h5>
                                                <div className='filter-drop-menu-p'>
                                                    <p>IGCSE</p>
                                                    <p>IGCSE (9-1)</p>
                                                    <p>A-LEVEL</p>
                                                </div>
                                            </div>
                                            <div className='filter-drop-menu-heading'>
                                                <h5>OCR</h5>
                                                <div className='filter-drop-menu-p'>
                                                    <p>IGCSE (9-1)</p>
                                                    <p>AS</p>
                                                    <p>A-LEVEL</p>
                                                </div>
                                            </div>
                                            <div className='filter-drop-menu-heading'>
                                                <h5>AQA</h5>
                                                <div className='filter-drop-menu-p'>
                                                    <p>IGCSE</p>
                                                    <p>AS</p>
                                                    <p>A-LEVEL</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Form.Label>Subject:</Form.Label>
                                    <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                        {selectedCategory || 'Accounting 9-1 (0985)'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* {categories.map(category => (
                                            <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                {category}
                                            </Dropdown.Item>
                                        ))} */}
                                        <div className='filter-drop-menu'>
                                            <div className='filter-drop-menu-heading'>
                                                <ul>
                                                    <li>Accounting 9-1 (0985)</li>
                                                    <li>Accounting (0452)</li>
                                                    <li>Additional Mathematics (0606)</li>
                                                    <li>Afrikaans 1st Language (0512)</li>
                                                    <li>Afrikaans 2nd Language (0548)</li>
                                                    <li>Agriculture (0600)</li>
                                                    <li>American History (US)(0409)</li>
                                                    <li>Arabic - First Language 9-1(7108)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                        {selectedCategory || 'Select Paper'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* {categories.map(category => (
                                            <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                {category}
                                            </Dropdown.Item>
                                        ))} */}
                                        <div className='filter-drop-menu'>
                                            <div className='filter-drop-menu-heading'>
                                                <ul>
                                                    <li>1</li>
                                                    <li>2</li>
                                                    <li>3</li>
                                                    <li>4</li>
                                                    <li>5</li>
                                                    <li>6</li>
                                                    <li>7</li>
                                                    <li>8</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                        {selectedCategory || 'Select Season'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* {categories.map(category => (
                                            <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                {category}
                                            </Dropdown.Item>
                                        ))} */}
                                        <div className='filter-drop-menu'>
                                            <div className='filter-drop-menu-heading'>
                                                <ul>
                                                    <li>2024</li>
                                                    <li>2023</li>
                                                    <li>2022</li>
                                                    <li>2021</li>
                                                    <li>2020</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                        {selectedCategory || 'Select Season'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* {categories.map(category => (
                                            <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                {category}
                                            </Dropdown.Item>
                                        ))} */}
                                        <div className='filter-drop-menu'>
                                            <div className='filter-drop-menu-heading'>
                                                <ul>
                                                    <li>Summer</li>
                                                    <li>Winter</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="form-select" id="dropdown-category">
                                        {selectedCategory || 'Select Zone'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {/* {categories.map(category => (
                                            <Dropdown.Item key={category} onClick={() => handleSelectChange({ target: { value: category } })}>
                                                {category}
                                            </Dropdown.Item>
                                        ))} */}
                                        <div className='filter-drop-menu'>
                                            <div className='filter-drop-menu-heading'>
                                                <ul>
                                                    <li>Zone 1</li>
                                                    <li>Zone 2</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Form>
                    </div>
                    <div className='Pastpaperexamcontent-page-filter-content desktop-view'>
                        <Row>
                            <Col sm={3}>
                                <div className='desktop-view'>
                                    <div className='Pastpaperexamcontent-page-filter-content-left'>
                                        <div className='Pastpaperexamcontent-page-filter-content-left-header'>
                                            <div className='Pastpaperexamcontent-page-filter-content-left-header-content'>
                                                <p>Asc</p>
                                                <p><span>1023</span></p>
                                                <p><span>1-25</span></p>
                                                <p>Random</p>
                                            </div>
                                        </div>
                                        <div className='Pastpaperexamcontent-page-filter-content-left-content'>
                                            <ul>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='Pagination-bar'>
                                        <Pagination>
                                            <Pagination.Prev />
                                            <Pagination.Item active>{1}</Pagination.Item>
                                            <Pagination.Item>{2}</Pagination.Item>
                                            <Pagination.Item>{3}</Pagination.Item>
                                            <Pagination.Ellipsis />
                                            <Pagination.Item>{32}</Pagination.Item>
                                            <Pagination.Item>{33}</Pagination.Item>
                                            <Pagination.Next />
                                        </Pagination>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={9}>
                                <div className='Pastpaperexamcontent-page-filter-content-right'>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-header'>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-header-content'>
                                            <p>Share</p>
                                            <p>Previous</p>
                                            <p>Next</p>
                                            <p><span>Question</span></p>
                                            <p>Answer</p>
                                        </div>
                                    </div>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-content'>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-content-header'>
                                            <Row>
                                                <Col sm={3}>
                                                    <p className='topic-color'>Topic(s):</p>
                                                </Col>
                                                <Col sm={9}>
                                                    <p>Multiplication And Division</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-content-img'>
                                            <p>
                                                <img src={questionimg} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='Pastpaperexamcontent-page-filter-content mobile-view'>
                        <Row>
                            <Col sm={3}>
                                <div className='mobile-view Pastpaperexamcontent-bottom-margen-mobile-view'>
                                    <div className='Pastpaperexamcontent-page-filter-content-left'>
                                        <div className='Pastpaperexamcontent-page-filter-content-left-content'>
                                            <ul className='Pastpaperexamcontent-mobile-view'>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                                <li>NUM8ERS Question 2023</li>
                                            </ul>
                                            <div className='Pagination-bar mobile-view-Pagination-bar'>
                                                <Pagination>
                                                    <Pagination.Prev />
                                                    <Pagination.Item active>{1}</Pagination.Item>
                                                    <Pagination.Item>{2}</Pagination.Item>
                                                    <Pagination.Item>{3}</Pagination.Item>
                                                    <Pagination.Ellipsis />
                                                    <Pagination.Item>{32}</Pagination.Item>
                                                    <Pagination.Item>{33}</Pagination.Item>
                                                    <Pagination.Next />
                                                </Pagination>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={12} className='Pastpaperexamcontent-top-content-1 '>
                                <div className='Pastpaperexamcontent-page-filter-content-right-header'>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-header-content'>
                                        <p>Share</p>
                                        <p>Previous</p>
                                        <p>Next</p>
                                        <p><span>Question</span></p>
                                        <p>Answer</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={9} className='Pastpaperexamcontent-top-content-2'>
                                <div className='Pastpaperexamcontent-page-filter-content-right'>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-header'>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-header-content'>
                                            <p className='topic-color'>Topic(s):</p>
                                            <p>Multiplication And Division</p>
                                        </div>
                                    </div>
                                    <div className='Pastpaperexamcontent-page-filter-content-right-content'>
                                        <div className='Pastpaperexamcontent-page-filter-content-right-content-img'>
                                            <p>
                                                <img src={questionimg} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div>
                    {/* <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Category</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map(item => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>{item.category}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> */}
                </div>

            </section>
        </>
    )
}

export default Pastpaperexamcontent_3