import React, { useState, useEffect, useContext } from "react";
import "./header.css";
import { DarkLightModeContext } from "react-light-dark-mode";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Toast from "react-bootstrap/Toast";
import NavDropdown from "react-bootstrap/NavDropdown";
import Black_Logo from "../../img/logo/black-logo.png";
import Whote_Logo from "../../img/logo/white-logo.png";
import { AiOutlineSearch } from "react-icons/ai";
import Search_bar from "../../img/icon/Search_alt.png";
import user from "../../img/icon/User_cicrle_light.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Right_Arror from "../../img/icon/right-arror.png";
import img_1 from "../../img/icon/Molecule_light.png";
import img_1_d from "../../img/icon/Molecule_light_w.png";
import Sunlight from "../../img/icon/Sunlight_light.png";
import Moonlight from "../../img/icon/Moon_light.png";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Right_Arror_d from "../../img/icon/rigth_arror_drack.png";
import { useGetAllcurriculumQuery } from "../../services/aplms";
import Sideheader from "./Sideheader";
import Ritsideheader from "./Ritsideheader";
import Loader from "../../loader/Loader";
import Error from "../../errordata/Error";
import mobilebutton from "../../img/icon/navbar.png";
import Accordion from "react-bootstrap/Accordion";
import Mobileleft from "./Mobileleft";
import Dropdown from "react-bootstrap/Dropdown";
import { getToken } from "../../services/LocalStorageService";
import Logout from "./Logout";
import Search from "../../search/Search";

const Header = ({ name, ...props }) => {
  // header new code start

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const header = document.getElementById("header-number-project");
    if (header) {
      if (scrolled) {
        header.classList.add("header-number-project");
      } else {
        header.classList.remove("header-number-project");
      }
    }
  }, [scrolled]);

  // end header new code

  const toggleShowA = () => setShowA(!showA);
  const [showA, setShowA] = useState(false);

  const token = getToken();

  const { lightMode, toggleLightDarkMode } = useContext(DarkLightModeContext);

  const toggleMode = () => {
    toggleLightDarkMode();
  };

  const curriculum = useGetAllcurriculumQuery();

  if (curriculum.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (curriculum.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (curriculum.isError) return <h1>data not found </h1>;

  return (
    <>
      <Navbar expand="lg" className="desktop-view" id="header-number-project">
        <Container fluid>
          <Navbar.Brand href="/">
            <img className="light" src={Black_Logo} />
            <img className="drack" src={Whote_Logo} />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="mobile-button"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0 header-center-content"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Dropdown
                className="nav-item dropdown megamenu"
                autoClose={false}
              >
                <Dropdown.Toggle id="dropdown-autoclose-false">
                  <Nav.Link
                    id="megamneu"
                    href="#"
                    aria-expanded="false"
                    className="nav-link dropdown-toggle"
                  >
                    Curriculum
                  </Nav.Link>
                </Dropdown.Toggle>

                <Dropdown.Menu className="border-0 p-0 m-0">
                  <div className="container">
                    <div className="row bg-trans rounded-10 m-0 shadow-sm">
                      <div className="col-lg-12 col-xl-12 pl-0 pr-0 drack-mode-header-drop-down">
                        <div className="row">
                          <Tabs
                            defaultActiveKey="ap"
                            id="uncontrolled-tab-example"
                            className="mb-4 megamenu-top-tabs"
                          >
                            {curriculum.data.map((curriculumdata) => {
                              return (
                                <Tab
                                  eventKey={curriculumdata.slug}
                                  title={curriculumdata.name}
                                >
                                  <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey={curriculumdata.slug}
                                  >
                                    <Sideheader
                                      propsintroData={curriculumdata.slug}
                                    />
                                  </Tab.Container>
                                </Tab>
                              );
                            })}
                          </Tabs>
                        </div>
                      </div>
                      <div className="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              {/* <li className="nav-item dropdown megamenu">
                <Nav.Link
                  id="megamneu"
                  href="#"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="nav-link dropdown-toggle">
                  Curriculum
                </Nav.Link>
                <div
                  aria-labelledby="dropdownMenuButton1"
                  className="dropdown-menu border-0 p-0 m-0">
                  <div className="container">
                    <div className="row bg-white rounded-0 m-0 shadow-sm">
                      <div className="col-lg-12 col-xl-12 pl-0 pr-0 drack-mode-header-drop-down">
                        <div className="row">
                          <Tabs
                            defaultActiveKey="ap"
                            id="uncontrolled-tab-example"
                            className="mb-4 megamenu-top-tabs">
                            {curriculum.data.map((curriculumdata) => {
                              return (
                                <Tab
                                  eventKey={curriculumdata.slug}
                                  title={curriculumdata.name}>
                                  <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey={curriculumdata.slug}>
                                    <Sideheader
                                      propsintroData={curriculumdata.slug}
                                    />
                                  </Tab.Container>
                                </Tab>
                              );
                            })}
                          </Tabs>
                        </div>
                      </div>
                      <div className="col-lg-5 col-xl-4 px-0 d-none d-lg-block megaimaga"></div>
                    </div>
                  </div>
                </div>
              </li> */}

              <Search />
            </Nav>

            {token ? (
              <>
                <Nav
                  className="ms-auto my-2 my-lg-0 header-right"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <Logout />

                  <Nav.Link href="/My-Profile">
                    <img src={user} />
                  </Nav.Link>

                  <Nav.Link href="/My-Profile" className="Profile-dropdown">
                    Profile
                  </Nav.Link>
                </Nav>
              </>
            ) : (
              <>
                <Nav
                  className="ms-auto my-2 my-lg-0 header-right"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <Nav.Link href="/login">Login</Nav.Link>
                  <Nav.Link href="/sinup" className="Profile-dropdown join-now">
                    Join Now
                  </Nav.Link>
                </Nav>
              </>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar expand="lg" className="mobile-view">
        <Container fluid>
          <div className="navbar-left">
            <Button variant="navbar" onClick={toggleShowA}>
              <img src={mobilebutton} />
            </Button>
            <Navbar.Brand href="/">
              <img className="light" src={Black_Logo} />
              <img className="drack" src={Whote_Logo} />
            </Navbar.Brand>
          </div>
          <div className="navbar-right">
            <Nav.Link href="/login" className="Profile-dropdown join-now">
              Join Now
            </Nav.Link>
            <Nav.Link href="/login" className="Search_bar">
              <img src={Search_bar} />
            </Nav.Link>
          </div>
        </Container>
      </Navbar>

      <Toast
        show={showA}
        onClose={toggleShowA}
        className="menu-drop-down-popup"
      >
        <Toast.Body>
          {curriculum.data.map((curriculumdata) => {
            return (
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>{curriculumdata.name}</Accordion.Header>
                  <Accordion.Body className="Accordion-Accordion_style">
                    <Mobileleft propsintroData={curriculumdata.slug} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            );
          })}
        </Toast.Body>
      </Toast>
{/* 
      <div className="text-end">
        <button onClick={toggleMode} className="buttonColor sun-moon">
          {lightMode ? <img src={Sunlight} /> : <img src={Moonlight} />}
        </button>
      </div> */}
    </>
  );
};

export default Header;
