import React, { useContext, useEffect, useState } from 'react';
import './quiz.css';
import { Link } from 'react-router-dom';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import { useGetAllquizquctionQuery } from '../services/aplms';
import HTMLReactParser from 'html-react-parser';
import happy_img from '../img/happy_light.png';
import sed_img from '../img/Sad_light.png';
import Quizpopup from './Quizpopup';
import { QuizContext } from './Context/QuizHolder';
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"


const Box = ({ current, next, props }) => {
  const questiondata = useGetAllquizquctionQuery(props.match.params.slug);

  const [model, setModel] = useState(false);
  const [check, setCheck] = useState(false);
  const [tempdata, setTempdata] = useState();
  const [iso, setIso] = useState(null);
  const Easy = 'Easy';
  const Medium = 'Medium';
  const Hard = 'Hard';
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const [correct, setCorrect] = useState(null);
  const [result, setResult] = useState(0);


  const [classadd, setClassadd] = useState('');
  const [disabledcheck, setDisabledcheck] = useState(false);
  const [ans, setAns] = useState('');
  const [exit, setExit] = useState(null);
  const { quizzes, setQuizzes } = useContext(QuizContext);
  const getData = (photo) => {
    let tempData = [photo];
    setTempdata((catdata) => [1, ...tempData]);
    return setModel(true);
  };




  useEffect(() => {
    if (questiondata.data) {
      setQuizzes(questiondata.data);
      setCorrect(null);
      setExit(
        <>
          <Link
            to="/Result"
            className="quiz-box">
            Show Result
          </Link>
        </>
      );
    }
  }, [questiondata.data, setQuizzes]);




  // Handler for saving the answer and updating UI
  const saveHandler = () => {
    const quizData = quizzes;

    if (!quizData || !quizData.questions) {
      // Handle the case where quizData or questions is undefined
      return;
    }

    const answerIndicator = quizData.questions[current].answer === ans ? 1 : 0;
    const storedIndicators =
      JSON.parse(localStorage.getItem('answerIndicators')) || [];
    storedIndicators[current] = answerIndicator;
    localStorage.setItem('answerIndicators', JSON.stringify(storedIndicators));

    if (quizData.questions[current].answer === ans) {
      setClassadd('A');
      setResult(result + quizData.questions[current].marks);

      setCorrect(
        <>
          <Button
            onClick={() => {
              nextHandler();
              setCorrect(null);
              setAns('');
            }}
            className="button-width_300">
            Continue
          </Button>
          <div className="wrong-answer">
            <p>Your Answer is Correct</p>
          </div>
          <div className="see-why-popup">
            <p
              className="heand-icon"
              onClick={() => getData(quizData.questions[current].fullanswer)}>
              See Why
            </p>
            <img
              src={happy_img}
              alt="Happy"
            />
          </div>
        </>
      );
    } else {
      setClassadd('B');
      setCorrect(
        <>
          <Button
            onClick={() => {
              nextHandler();
              setCorrect(null);
              setAns('');
            }}
            className="quiz-box red-button">
            Continue
          </Button>
          <div className="wrong-answer red">
            <p>Your Answer is Wrong</p>
          </div>
          <div className="see-why-popup red">
            <p
              className="heand-icon"
              onClick={() => getData(quizData.questions[current].fullanswer)}>
              See Why
            </p>
            <img
              src={sed_img}
              alt="Sad"
            />
          </div>
        </>
      );
    }

    if (current + 1 === quizData.questions.length) {
      setCheck(false);
      setCorrect(null);
      setDisabledcheck(false);

      setExit(
        <>
          <Link
            to="/Result"
            className="quiz-box">
            Show Result
          </Link>
        </>
      );
    } else {
      setDisabledcheck(true);
    }

    setQuizzes({ ...quizzes, result });
  };




  const nextHandler = () => {
    next(current + 1);
    setDisabledcheck(false);
    setClassadd('');
  };

  // Check if questiondata.data or questions is undefined
  if (!questiondata.data || !questiondata.data.questions) {
    // Handle the case where questiondata or questions is undefined
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <section>
        {/* Header Section */}
        <div className="top-header-quiz">
          <Header />
          {/* Quiz Section */}
          <section className="ap">
            <Container>
            <img
            src={BGRight1}
            alt="Splatter"
            className="splatter-image-right-1"
          />
          <img
            src={BGRight2}
            alt="Splatter"
            className="splatter-image-right-2"
          />
          <img
            src={BGMiddle}
            alt="Splatter"
            className="splatter-image-middle"
          />
              <Row className="pb-30">
                <Col sm={12}>
                  <div className="ap-heading mb-60 pt-60">
                    <Row className="quiz_row_page">
                      <Col
                        sm={12}
                        className="quiz_top_header_button">
                        {/* Display question numbers */}
                        {questiondata.data.questions.map((unit, index) => (
                          <p
                            className={`${index === current ? 'quizquctionnumber' : ''
                              }`}
                            key={index}>
                            {index + 1}
                          </p>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {/* Display current question */}
                  <div className="quiz_question_top">
                    <p>
                      {HTMLReactParser(
                        questiondata.data.questions[current].question
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        {/* Main Quiz Section */}
        <section className="unit-page pt-60 pb-120">
          <Container>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={6}>
                    {/* Display answer options */}
                    <div className="quiz_main_content pt-30 pb-60">
                      <Form>
                        {['radio'].map((type) => (
                          <div
                            key={`inline-${type}`}
                            className="mb-3">
                            {/* Display radio buttons for answer options */}
                            <Form.Check
                              onClick={() => {
                                setAns('A');
                                setCheck(true);
                              }}
                              className={`${classadd} chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].A
                              )}
                              name="group1"
                              type={type}
                              id={`inline-${type}-1`}
                              disabled={disabledcheck}
                              defaultChecked={false}
                              checked={ans === 'A'}
                            />

                            <Form.Check
                              onClick={() => {
                                setAns('B');
                                setCheck(true);
                              }}
                              className={`${classadd} chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].B
                              )}
                              name="group1"
                              type={type}
                              id={`inline-${type}-2`}
                              disabled={disabledcheck}
                              checked={ans === 'B'}
                            />

                            <Form.Check
                              onClick={() => {
                                setAns('C');
                                setCheck(true);
                              }}
                              className={`${classadd} chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].C
                              )}
                              name="group1"
                              type={type}
                              id={`inline-${type}-3`}
                              disabled={disabledcheck}
                              checked={ans === 'C'}
                            />

                            <Form.Check
                              onClick={() => {
                                setAns('D');
                                setCheck(true);
                              }}
                              className={`${classadd} chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].D
                              )}
                              name="group1"
                              type={type}
                              id={`inline-${type}-4`}
                              disabled={disabledcheck}
                              checked={ans === 'D'}
                            />
                          </div>
                        ))}
                      </Form>
                    </div>
                  </Col>
                </Row>
                <div className="quiz_main_bottom_content">
                  <p>
                    Note: **Get your result by clicking <span>Check</span>{' '}
                    button and then click continue for the next question.
                  </p>
                </div>
                <div className="display-flex quiz-page-style ">
                  <div className="quiz_bottom_button">
                    <>
                      {disabledcheck === false && (
                        <>
                          {check === true ? (
                            <Button
                              onClick={saveHandler}
                              className="quiz-box"
                              disabled={!ans}
                            >
                              Check
                            </Button>
                          ) : (
                            <>
                              {current + 1 ===
                                questiondata.data.questions.length ? (
                                exit
                              ) : (
                                <Button
                                  className="quiz-box-start"
                                  onClick={nextHandler}
                                  disabled={!ans}>
                                  Check
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </div>

                  {/* Display correct/wrong feedback */}
                  {correct}
                  <div className="quiz_bottom_right">
                    <p>Save time with our</p>
                    <Button
                      variant="popup-button-1"
                      onClick={handleShow}>
                      Keyboard Shortcuts
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Keyboard Shortcuts Modal */}
        <Modal
          className="Keyboard-Shortcuts"
          size="lg"
          centered
          show={showh}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pb-120">
            <h4 className="mb-30">Keyboard Shortcuts</h4>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Submit Answer or Continue</p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>ENTER</p>
              </div>
            </div>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Select Multiple Choice Answer</p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>a</p>
                <p>b</p>
                <p>c</p>
                <p>d</p>
              </div>
            </div>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Reset Blanks Exercise </p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>R</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </section>

      {/* Display the Quizpopup component when model is true */}
      {model === true ? (
        <Quizpopup
          photo={tempdata[1]}
          hide={() => setModel(false)}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Box;
