import React from 'react';
import './popupphoto.css';
import HTMLReactParser from 'html-react-parser';
import Modal from 'react-bootstrap/Modal';

const Popupphoto = (props) => {
  return (
    <>


      <div className="photo_popup_main modal">
        <div className="main-photo-popup main-photo-popup_photo">
          
        <Modal.Header className="full-img-popup" closeButton onClick={props.hide}>
          <Modal.Title></Modal.Title>
        </Modal.Header>
          
          <Modal.Body>
        <p className="img-fluid">{HTMLReactParser(props.photo)}</p>
        </Modal.Body>
        </div>
      </div>
    </>
  );
};

export default Popupphoto;
