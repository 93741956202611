import React from 'react';
import './ap/ap.css';
import Key from '../img/icon/Molecule_light.png';
import Question from '../img/icon/Quiz.png';
import Past from '../img/icon/Book_open_alt_light.png';
import Quiz from '../img/icon/Book.png';
import Key_w from '../img/icon/Molecule_light_w.png';
import Question_w from '../img/icon/Quiz_w.png';
import Past_w from '../img/icon/Book_open_alt_light_w.png';
import Quiz_w from '../img/icon/Book_w.png';
import { useGetAllcoursesQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import { NavLink } from 'react-router-dom';

const Course = (props) => {
  const slug = props.propsData;
  const couresdata = useGetAllcoursesQuery(slug);
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null)
    return (
      <div>
        <h1></h1>
      </div>
    );
  if (couresdata.isError) return <Error />;
  return (
    <>
      <ul className="ap-box-content">
        {couresdata.data.map((coures) => {
          return (
            <li className="ap-box-content-button">
              <p className="courses-button-style">{coures.name}</p>
              <div className="icon-img courses-button-style">
                <NavLink to={'/Key-Concept/' + coures.slug}>
                  <img
                    src={Key}
                    className="d"
                  />
                  <img src={Key_w} />
                </NavLink>
                <NavLink to={'/Question-Bank/' + coures.slug}>
                  <img
                    src={Question}
                    className="d"
                  />
                  <img src={Question_w} />
                </NavLink>
                <NavLink to={'/Past-Paper-Unit/' + coures.slug}>
                  <img src={Past_w} />

                  <img
                    src={Past}
                    className="d"
                  />
                </NavLink>

                <NavLink to={'/Quiz/' + coures.slug}>
                  <img
                    src={Quiz}
                    className="d"
                  />{' '}
                  <img src={Quiz_w} />
                </NavLink>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Course;
