import React from 'react';
import './ap.css';
// bootstrap
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// page
import Header from '../../layout/header/Header';
import Top_header from './Top_header';
import Footer from '../../layout/footer/Footer';
// img
import Key from '../../img/icon/Molecule_light.png';
import Question from '../../img/icon/Quiz.png';
import Past from '../../img/icon/Book_open_alt_light.png';
import Quiz from '../../img/icon/Book.png';
import Key_w from '../../img/icon/Molecule_light_w.png';
import Question_w from '../../img/icon/Quiz_w.png';
import Past_w from '../../img/icon/Book_open_alt_light_w.png';
import Quiz_w from '../../img/icon/Book_w.png';
import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';
import { useGetAllsubjectQuery } from '../../services/aplms';
import Coursecard from './Coursecard';
import { Pagenotfound } from '../../404/Pagenotfound';
// pakege
import { Link } from 'react-router-dom';

const Ap = (props) => {
  const couresdata = useGetAllsubjectQuery(props.match.params.slug);
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );
  if (couresdata.isError) return <Error />;

  console.log(couresdata.data);
  return (
    <section>
      <div className="top-header ap-mobile-view-header">
        <Header />
        <Top_header propsintroData={props.match.params.slug} />
      </div>
      <section className="ap-main-content">
        {couresdata.data.map((subject) => {
          return (
            <Container>
              <h4 className="top-title">{subject.name}</h4>
              <Row>
                <Coursecard propsData={subject.slug} />
              </Row>
            </Container>
          );
        })}
      </section>
      <Footer />
    </section>
  );
};

export default Ap;
