import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useGetMonthplansQuery } from "../../services/aplms";
import Error from "../../errordata/Error";
import Loader from "../../loader/Loader";
import { post } from "../../services/CallAPi";
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "./Payment.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import BGRight1 from "../../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../../img/BG Middle.png"; // Import the background image

const Payment = () => {
  const [setCheckedGroup, setsetCheckedGroup] = useState("group1");
  const [intervalCount, setIntervalCount] = useState(1);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedPlanids, setSelectedPlanids] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const plan = useGetMonthplansQuery(intervalCount);
  const history = useHistory();

  useEffect(() => {
    if (plan.data && plan.data.data.length > 0) {
      const firstCourse = plan.data.data[0].couresname;
      setSelectedCourses([firstCourse]);
    }
  }, [plan.data]);

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    selectedCourses.forEach((course) => {
      const selectedCourse = plan.data.data.find(
        (item) => item.couresname === course
      );
      if (selectedCourse) {
        totalPrice += parseFloat(selectedCourse.price);
      }
    });
    return totalPrice.toFixed(2); // Fix to two decimal places
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [selectedCourses, plan.data, intervalCount]);

  if (plan.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (plan.isError) return <Error />;

  const handleClick = async () => {
    if (!localStorage.getItem("token")) {
      history.push("/login");
    } else {
      const token = localStorage.getItem("token");
      console.log(token);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        // Create an array to hold all selected plan IDs
        const allSelectedPlanIds = [];

        // Iterate over selectedPlanids to push all selected plan IDs into allSelectedPlanIds
        selectedPlanids.forEach((planId) => {
          allSelectedPlanIds.push({ plan_id: planId });
        });

        // Send all selected plan IDs in a single request
        const { data } = await axios.post(
          `student/checkout`,
          { plans: allSelectedPlanIds },
          config
        );
        // console.log(data.url);
        window.location.replace(data.url);
      } catch (error) {
        console.error("Error during checkout:", error);
        // Handle checkout errors appropriately (e.g., display an error message)
      }
    }
  };

  const handleCheck = (groupName, count) => {
    setsetCheckedGroup(groupName);
    setIntervalCount(count);
    setSelectedCourses([]);
    setSelectedPlanids([]);
  };

  const handleCourseSelection = (course, planId) => {
    const index = selectedCourses.indexOf(course);
    if (index === -1) {
      setSelectedCourses([...selectedCourses, course]);
      setSelectedPlanids([...selectedPlanids, planId]); // Add plan ID
    } else {
      const updatedCourses = [...selectedCourses];
      const updatedPlanIds = [...selectedPlanids];
      updatedCourses.splice(index, 1);
      const planIdIndex = updatedPlanIds.findIndex((id) => id === planId);
      updatedPlanIds.splice(planIdIndex, 1);
      setSelectedCourses(updatedCourses);
      setSelectedPlanids(updatedPlanIds);
    }
  };

  const plansBySubject = {};
  plan.data.data.forEach((plan) => {
    const subjname = plan.subjname;
    if (!plansBySubject[subjname]) {
      plansBySubject[subjname] = [];
    }
    plansBySubject[subjname].push(plan);
  });

  return (
    <section className="payment-page">
      <Header />
      <img src={BGRight1} alt="Splatter" className="splatter-image-right-1" />
      <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
      <img src={BGMiddle} alt="Splatter" className="splatter-image-middle" />
      <Container className="pb-120">
        <Row>
          <Col sm={12}>
            <div className="payment-page-heading text-center">
              <h2>Pricing</h2>
              <p>
                Thank you for supporting <br />
                We are working hard to build amazing resources for students
                <br />
                World Wide
              </p>
            </div>
          </Col>
        </Row>

        <Row className="pt-30">
          <Col sm={12}>
            <div className="payment_top_button">
              <div className="payment_month_and_year_button">
                <Form>
                  {["radio"].map((type) => (
                    <div key={`inline-${type}`} className="mobile_view_style">
                      <Form.Check
                        inline
                        label="1 month"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                        defaultChecked
                        checked={setCheckedGroup === "group1"}
                        onClick={() => handleCheck("group1", 1)}
                      />
                      <Form.Check
                        inline
                        label="3 month"
                        name="group2"
                        type={type}
                        id={`inline-${type}-2`}
                        checked={setCheckedGroup === "group2"}
                        onClick={() => handleCheck("group2", 3)}
                      />
                      <Form.Check
                        inline
                        label="6 month"
                        name="group3"
                        type={type}
                        id={`inline-${type}-3`}
                        checked={setCheckedGroup === "group3"}
                        onClick={() => handleCheck("group3", 6)}
                      />
                      <Form.Check
                        inline
                        label="12 month"
                        name="group4"
                        type={type}
                        id={`inline-${type}-4`}
                        checked={setCheckedGroup === "group4"}
                        onClick={() => handleCheck("group4", 12)}
                      />
                    </div>
                  ))}
                </Form>
              </div>
            </div>
          </Col>
        </Row>

        {Object.keys(plansBySubject).map((subjname, subjIndex) => (
          <Row key={subjIndex} className="pb-30">
            <Col sm={12}>
              <div className="payment_page_top_heading mt-30 mb-30">
                <h2>{subjname}</h2>
              </div>
            </Col>
            {plansBySubject[subjname].map((plan, planIndex) => (
              <Col key={planIndex} sm={3}>
                <div className="payment_card_box">
                  <div className="payment_card_price">
                    <p>${plan.price}</p>
                  </div>
                  <h4 className="payment_card_title">{plan.couresname}</h4>

                  <div className="payment_card_box_body">
                    <h4>Per Month, Billed Monthly</h4>
                    <ul>
                      <li>
                        <FaCheck />
                        <span>Key Concepts</span>
                      </li>
                      <li>
                        <FaCheck />
                        <span>Question Bank</span>
                      </li>
                      <li>
                        <FaCheck />
                        <span>Past Papers</span>
                      </li>
                      <li>
                        <FaCheck />
                        <span>Quizzes</span>
                      </li>
                    </ul>
                  </div>

                  <button
                    className="checkbox_button"
                    onClick={() =>
                      handleCourseSelection(plan.couresname, plan.id)
                    }
                  >
                    <input
                      type="checkbox"
                      checked={selectedCourses.includes(plan.couresname)}
                      onChange={() => {
                        handleCourseSelection(plan.couresname, plan.id);
                      }}
                    />
                    <span>Subscribe</span>
                  </button>
                </div>
              </Col>
            ))}
          </Row>
        ))}

        <Button onClick={handleClick} className="bottom-button-more">
          Get started - ${totalPrice}
        </Button>

        {/* <div>
          <h2>Selected Courses:</h2>
          <ul>
            {selectedCourses.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        </div> */}
      </Container>
      <Footer />
    </section>
  );
};

export default Payment;
