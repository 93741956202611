import React, { useState, useEffect } from "react";
import Isotope from "isotope-layout";
import Filter from "./Filter";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ReactFlagsSelect, { Ro } from "react-flags-select";
import { Us } from "react-flags-select";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import Header from "../layout/header/Header";
import { useDispatch } from "react-redux";
import img_1 from "../img/paper-photo.png";
import img_3 from "../img/for.png";
import img_2 from "../img/profile_img.png";
import img_4 from "../img/icon/Arhive_export.png";
import img_5 from "../img/icon/Img_box_light.png";
import img_6 from "../img/icon/Camera_duotone_line.png";
import img_7 from "../img/icon/Credit_card.png";
import img_8 from "../img/icon/cib_apple-pay.png";
import img_9 from "../img/icon/card.png";
import img_10 from "../img/icon/card-black.png";
import { NavLink, useHistory } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  useLogoutUserMutation,
  useGetLoggedUserQuery,
} from "../services/aplms";

import {
  getToken,
  removeToken,
  removeUserinfo,
} from "../services/LocalStorageService";
import { setUserInfo, unsetUserInfo } from "../features/userSlice";
import { unsetUserToken } from "../features/authSlice";

const View = ({ blogs, handleBlogs, filterValue, setFilterValue, props }) => {
  const [value, setValue] = useState();

  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/login");
    }
  }, []);
  const [userData, setUserData] = useState({
    email: "",
    fname: "",
    lname: "",
    phone: "",
    school: "",
    country: "",
    plane_status: "",
  });

  const token = getToken();
  const { data, isSuccess } = useGetLoggedUserQuery(token);

  useEffect(() => {
    if (data && isSuccess) {
      setUserData({
        email: data.user.email,
        fname: data.user.fname,
        lname: data.user.lname,
        phone: data.user.phone,
        country: data.user.country,
        school: data.user.school,
        plane_status: data.user.status,
      });
    }
  }, [data, isSuccess]);

  // Store User Data in Redux Store
  const dispatch = useDispatch();
  useEffect(() => {
    if (data && isSuccess) {
      dispatch(
        setUserInfo({
          email: data.user.email,
          name: data.user.name,
          phone: data.user.name,
          country: data.user.country,
          school: data.user.school,
          plane_status: data.user.status,
        })
      );
    }
  }, [data, isSuccess, dispatch]);

  const openInNewTab = (userData) => {
    window.open(userData.invoice_paf, "_blank", "noreferrer");
  };
  const [selected, setSelected] = useState("Profile");
  const [iso, setIso] = useState(null);
  const Easy = "Easy";
  const Medium = "Medium";
  const Hard = "Hard";

  useEffect(() => {
    setIso(
      new Isotope(".grid", {
        itemSelector: ".filter-item",
        layoutMode: "fitRows",
        filter: ".Profile",
      })
    );
  }, []);

  // handling filter key change
  useEffect(() => {
    if (iso) {
      filterValue === "All"
        ? iso.arrange({ filter: "*" })
        : iso.arrange({ filter: `.${filterValue}` });
    }
  }, [filterValue]);

  const [show, setShow] = useState(false);
  const [showh, sethShow] = useState(false);

  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const fullimageClose = () => setShow(false);
  const fullimageShow = () => setShow(true);
  return (
    <div className="App">
      <>
        <div className="top-header background-remove">
          <Header />
          <section className="ap desktop-view">
            <Container>
              <Row className="pb-60">
                <Col sm={12}>
                  <div className="myprofile-heading pt-30">
                    <h2>My Profile</h2>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Container>
            <Row className="desktop-view">
              <Col sm={12}>
                <div className="filter_myprofile_top">
                  <Filter setFilterValue={setFilterValue} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="grid">
                  <div className="filter-item first Profile pb-60">
                    <div className="desktop-view">
                      <div className="myprofile-password pt-30 pb-30">
                        <div className="text-start">
                          <p className="text-start">Personal Info</p>
                          <p className="update-p-text">
                            Update your photo and personal details here
                          </p>
                        </div>
                        <div className="myprofile-password-update">
                          <Button variant="Cancel" type="Cancel">
                            Cancel
                          </Button>
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </div>
                      </div>
                      <div className="myprofile_update_main pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalName"
                        >
                          <Form.Label column sm={4}>
                            First Name & Last Name
                          </Form.Label>
                          <Col sm={4}>
                            <Form.Control
                              type="name"
                              placeholder="First Name"
                              value={userData.name}
                            />
                          </Col>
                          <Col sm={4}>
                            <Form.Control type="name" placeholder="Last Name" />
                          </Col>
                        </Form.Group>
                      </div>
                      <div className="myprofile_update_main pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalName"
                        >
                          <Form.Label column sm={4}>
                            Email
                          </Form.Label>
                          <Col sm={8}>
                            <Form.Control
                              type="email"
                              placeholder={userData.email}
                              value={userData.email}
                            />
                          </Col>
                        </Form.Group>
                      </div>
                      <div className="myprofile_update_main pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3 align-items"
                          controlId="formHorizontalName"
                        >
                          <Form.Label column sm={4}>
                            Your Photo
                            <p>This photo will be displayed on you profile</p>
                          </Form.Label>
                          <Col sm={2}>
                            <div className="profile_img">
                              <img src={img_2} />
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div className="parent">
                              <div className="file-upload">
                                <img src={img_4} alt="upload" />
                                <div className="profile_img_upload_input">
                                  <img src={img_5} />
                                  <img src={img_6} />
                                  <p>Click upload or drag and drop</p>
                                </div>
                                <p className="upload_file_type_text">
                                  PNG , JPG
                                </p>
                                <input type="file" />
                              </div>
                            </div>
                          </Col>
                        </Form.Group>
                      </div>
                      <div className="myprofile_update_main pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalName"
                        >
                          <Form.Label column sm={4}>
                            Phone
                          </Form.Label>
                          <Col sm={8}>
                            <PhoneInput
                              className="form-control Country_code_input"
                              international
                              defaultCountry="RU"
                              value={value}
                              onChange={setValue}
                            />
                          </Col>
                        </Form.Group>
                      </div>
                      <div className="myprofile_update_main bt-border-none pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalName"
                        >
                          <Form.Label column sm={4}>
                            Country
                          </Form.Label>
                          <Col sm={8}>
                            <ReactFlagsSelect
                              className="flags_select_input"
                              selected={selected}
                              onSelect={(code) => setSelected(code)}
                            />
                          </Col>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="mobile-view">
                      <div className="myprofile_update_main pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3 align-items"
                          controlId="formHorizontalName"
                        >
                          <Col sm={2}>
                            <div className="profile_img">
                              <img src={img_2} />
                            </div>
                            <Form.Label column sm={4}>
                              Your Photo
                              <p>This photo will be displayed on you profile</p>
                            </Form.Label>
                          </Col>
                          <Col sm={6}>
                            <div className="parent">
                              <div className="file-upload">
                                <div className="profile_img_upload_input">
                                  <div>
                                    <img src={img_5} />
                                    <img src={img_6} />
                                  </div>
                                  <p className="upload_file_type_text">
                                    PNG , JPG
                                  </p>
                                </div>

                                <input type="file" />
                              </div>
                            </div>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalName">
                          <Col sm={4}>
                            <Form.Control type="name" placeholder="Julia" />
                          </Col>
                          <Col sm={4}>
                            <Form.Control type="name" placeholder="Jacent" />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalName">
                          <Col sm={8}>
                            <Form.Control
                              type="email"
                              placeholder="Julia@gmail.com"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalName">
                          <Col sm={8}>
                            <Form.Control
                              type="number"
                              placeholder="0525428701"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalName">
                          <Col sm={8}>
                            <ReactFlagsSelect
                              className="flags_select_input"
                              selected={selected}
                              onSelect={(code) => setSelected(code)}
                            />
                          </Col>
                        </Form.Group>
                        <div className="myprofile-password pt-30 pb-30">
                          <div className="myprofile-password-update">
                            <Button variant="primary" type="submit">
                              Save Changes
                            </Button>
                            <Button variant="Cancel" type="Cancel">
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-item Password pb-60">
                    <div className="desktop-view">
                      <div className="myprofile-password pt-30 pb-30">
                        <p>Update Your Password</p>
                        <div className="myprofile-password-update">
                          <Button variant="Cancel" type="Cancel">
                            Cancel
                          </Button>
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </div>
                      </div>
                      <div className="myprofile-password-from pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalPassword"
                        >
                          <Form.Label column sm={2}>
                            Current Password
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="password"
                              placeholder="1234556"
                            />
                          </Col>
                        </Form.Group>
                      </div>
                      <div className="myprofile-password-from pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalPassword"
                        >
                          <Form.Label column sm={2}>
                            New Password
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="password"
                              placeholder="*******"
                            />
                          </Col>
                        </Form.Group>
                      </div>
                      <div className="myprofile-password-from bt-border-none pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalPassword"
                        >
                          <Form.Label column sm={2}>
                            Confirm Password
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              type="password"
                              placeholder="*******"
                            />
                          </Col>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="mobile-view">
                      <Col sm={2}>
                        <div className="profile_img">
                          <img src={img_2} />
                        </div>
                        <Form.Label column sm={4}>
                          <p>Update Your Password</p>
                        </Form.Label>
                      </Col>
                      <div className="myprofile-password-from pt-30 pb-30">
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalPassword"
                        >
                          <Col sm={10}>
                            <Form.Control
                              type="password"
                              placeholder="1234556"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalPassword"
                        >
                          <Col sm={10}>
                            <Form.Control
                              type="password"
                              placeholder="*******"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-3"
                          controlId="formHorizontalPassword"
                        >
                          <Col sm={10}>
                            <Form.Control
                              type="password"
                              placeholder="*******"
                            />
                          </Col>
                        </Form.Group>
                      </div>
                      <div className="myprofile-password">
                        <div className="myprofile-password-update">
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                          <Button variant="Cancel" type="Cancel">
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-item Billing pb-60">
                    <div className="desktop-view">
                      <div className="myprofile-password bt-border-none pt-30 pb-30">
                        <p>Payments</p>
                        <Table className="Billing_table pt-30">
                          <thead>
                            <tr>
                              <th style={{ width: 200 }}>Order Date</th>
                              <th style={{ width: 350 }}>Contract</th>
                              <th style={{ width: 250 }}>Price</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>2024-02-01</td>
                              <td>Unlimited -free-IB Math</td>
                              <td>200 AED</td>
                              <td>Confirmed</td>
                            </tr>
                            <tr>
                              <td>2024-02-01</td>
                              <td>Unlimited -free-IB Math</td>
                              <td>200 AED</td>
                              <td>Confirmed</td>
                            </tr>
                            <tr>
                              <td>2024-02-01</td>
                              <td>Unlimited -free-IB Math</td>
                              <td>200 AED</td>
                              <td>Confirmed</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div className="myprofile-card bt-border-none pt-30 pb-30">
                        <p>Card Details</p>
                        <div className="mt-60">
                          <Row className="align-items">
                            <Col sm={6}>
                              <div className="upi_payment_left">
                                <img src={img_7} />
                                <img src={img_8} />
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className="upi_payment_right">
                                <img src={img_9} />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="myprofile-password bt-border-none pt-30 pb-30">
                        <p>Contract</p>
                        <Table className="Billing_table pt-30">
                          <thead>
                            <tr>
                              <th style={{ width: 200 }}>Start Date</th>
                              <th style={{ width: 250 }}>Product</th>
                              <th style={{ width: 250 }}>Price</th>
                              <th style={{ width: 250 }}>Renewal Date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>2024-02-01</td>
                              <td>IB Math</td>
                              <td>200 AED</td>
                              <td>2024-03-01</td>
                              <td>Confirmed</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <div className="mobile-view">
                      <p>Payment Method</p>
                      <div className="myprofile-card bt-border-none pt-30">
                        <div>
                          <Row className="align-items">
                            <Col sm={12}>
                              <div className="upi_payment_right">
                                <img src={img_10} />
                              </div>
                            </Col>
                            <Col sm={12}>
                              <Accordion className="mt-30">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="Accordion-header-text-style">
                                    <h4>Your Contract</h4>
                                    <p>Contract Expiry 01/24</p>
                                    <p>....</p>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="myprofile-password bt-border-none pt-30 pb-30">
                                      <p>Contract</p>
                                      <Table className="Billing_table pt-30">
                                        <thead>
                                          <tr>
                                            <th style={{ width: 200 }}>
                                              Start Date
                                            </th>
                                            <th style={{ width: 250 }}>
                                              Product
                                            </th>
                                            <th style={{ width: 250 }}>
                                              Price
                                            </th>
                                            <th style={{ width: 250 }}>
                                              Renewal Date
                                            </th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>2024-02-01</td>
                                            <td>IB Math</td>
                                            <td>200 AED</td>
                                            <td>2024-03-01</td>
                                            <td>Confirmed</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                              <Accordion className="mt-30">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header className="Accordion-header-text-style">
                                    <h4>Upgrade to Pro</h4>
                                    <p>$99.00 / month</p>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <div className="myprofile-password bt-border-none pt-30 pb-30">
                                      <Table className="Billing_table pt-30">
                                        <thead>
                                          <tr>
                                            <th style={{ width: 200 }}>
                                              Order Date
                                            </th>
                                            <th style={{ width: 350 }}>
                                              Contract
                                            </th>
                                            <th style={{ width: 250 }}>
                                              Price
                                            </th>
                                            <th>Status</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>2024-02-01</td>
                                            <td>Unlimited -free-IB Math</td>
                                            <td>200 AED</td>
                                            <td>Confirmed</td>
                                          </tr>
                                          <tr>
                                            <td>2024-02-01</td>
                                            <td>Unlimited -free-IB Math</td>
                                            <td>200 AED</td>
                                            <td>Confirmed</td>
                                          </tr>
                                          <tr>
                                            <td>2024-02-01</td>
                                            <td>Unlimited -free-IB Math</td>
                                            <td>200 AED</td>
                                            <td>Confirmed</td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Col>
                            <Col sm={12}>
                              <p className="mb-30 mt-30">
                                Choose another method
                              </p>
                              <div className="upi_payment_left">
                                <img src={img_7} />
                                <img src={img_8} />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-item Notifications pb-60">
                    <div className="desktop-view">
                      <div className="myprofile-password pt-30 pb-30">
                        <p>Notifications & News Updates</p>
                        <div className="myprofile-password-update">
                          <Button variant="Cancel" type="Cancel">
                            Cancel
                          </Button>
                          <Button variant="primary" type="submit">
                            Save Changes
                          </Button>
                        </div>
                      </div>
                      <div className="myprofile-password-from pt-30 pb-30">
                        <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check
                                className="checkbox_notifications"
                                type={type}
                                id={`default-${type}`}
                                label={`Future updates on Email`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                      <div className="myprofile-password-from pt-30 pb-30">
                        <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check
                                className="checkbox_notifications"
                                type={type}
                                id={`default-${type}`}
                                label={`Future updates on Phone`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                      <div className="myprofile-password-from bt-border-none pt-30 pb-30">
                        <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check
                                className="checkbox_notifications"
                                type={type}
                                id={`default-${type}`}
                                label={`Billing Updates `}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                    </div>
                    <div className="mobile-view">
                      <div className="myprofile-password pt-30 pb-30">
                        <p>Notifications & News Updates</p>
                      </div>
                      <div className="pb-30">
                        <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check
                                className="checkbox_notifications"
                                type={type}
                                id={`default-${type}`}
                                label={`Future updates on Email`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                      <div className="pb-30">
                        <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check
                                className="checkbox_notifications"
                                type={type}
                                id={`default-${type}`}
                                label={`Future updates on Phone`}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                      <div className="bt-border-none pb-30">
                        <Form>
                          {["checkbox"].map((type) => (
                            <div key={`default-${type}`}>
                              <Form.Check
                                className="checkbox_notifications"
                                type={type}
                                id={`default-${type}`}
                                label={`Billing Updates `}
                              />
                            </div>
                          ))}
                        </Form>
                      </div>
                      <div className="myprofile-password-update">
                        <Button variant="primary" type="submit">
                          Save Changes
                        </Button>
                        <Button variant="Cancel" type="Cancel">
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mobile-view">
              <Col sm={12}>
                <p className="filter_myprofile_top-text">
                  By continuing, you accept our <span>Terms of Use,</span> our
                  <span> Privacy Policy</span> and that your data is stored in
                  the cloud.
                </p>
                <div className="filter_myprofile_top">
                  <Filter setFilterValue={setFilterValue} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          {...props}
          size="xl"
          centered
          show={show}
          onHide={fullimageClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="full-img-popup" closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img src={img_1} />
          </Modal.Body>
        </Modal>
        <Modal
          className="Formula-popup"
          {...props}
          size="xl"
          centered
          show={showh}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="full-img-popup" closeButton></Modal.Header>
          <Modal.Body>
            <img src={img_3} />
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
};
export default View;
