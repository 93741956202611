import React from 'react';
import Hero from '../../hero/Hero';
import Courses from '../../courses/Courses';
import Brand from '../../brand/Brand';
import Charting from '../../charting/Charting';
import Speaks from '../../speaks/Speaks';
import Footer from '../footer/Footer';
import Header from '../header/Header';

const home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Courses />
      <Brand />
      <Charting />
      <Speaks />
      <Footer />
    </>
  );
};

export default home;
