import React from 'react';
import './ap/ap.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { NavLink } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Top_header = () => {
  return (
    <section className="ap">
      <Container>
        <Row className="pb-120">
          <Col sm={12}>
            <div className="top-nav">
              <Breadcrumb>
                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="/All-Curriculum">
                  Curriculum
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </Col>
          <Col sm={12}>
            <div className="ap-heading">
              <h2>All Curriculum</h2>
              <p>
                AP gives students the chance to tackle college-level work while
                they're still in high school—whether<br></br>
                they're learning online or in the classroom. And through taking
                AP Exams, <br></br>
                students can earn college credit and placement.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Top_header;
