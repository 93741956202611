import React from "react";
import "./footer.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import img_1 from "../../img/logo/black-logo.png";
import img_8 from "../../img/logo/white-logo.png";
import img_2 from "../../img/icon/Social-Icons-1.png";
import img_3 from "../../img/icon/Social-Icons-2.png";
import img_4 from "../../img/icon/Social-Icons-3.png";
import img_5 from "../../img/icon/Social-Icons-4.png";
import img_6 from "../../img/icon/Social-Icons-5.png";
import img_7 from "../../img/icon/Social-Icons-6.png";
import BGMiddle from "../../img/BG Middle.png"; // Import the background image

const Footer = () => {
  return (
    <>
      <section className="footer pt-60 desktop-view">
        <Container>
          <Row className="pb-120">
            <Col sm={3}>
              <div className="footer-top">
                <h4>Curriculum</h4>
                <ul>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      AP
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      IB
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      GCSE
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      IGCSE
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={3}>
              <div className="footer-top">
                <h4>Resources</h4>
                <ul>
                  <li>
                    <Link to="/Blog" className="footer-link-style">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Formulas
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Books
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Tutorials
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={3}>
              <div className="footer-top">
                <h4>Plans</h4>
                <ul>
                  <li>
                    <Link to="/Payment" className="footer-link-style">
                      Pricing
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={3}>
              <div className="footer-top">
                <h4>Support</h4>
                <ul>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Help Centre
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Career
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/Contact-us" className="footer-link-style">
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="line"></div>
          <Row>
            <Col sm={12}>
              <div className="footer-logo">
                <Link to="/">
                  <img src={img_1} className="drack" />
                </Link>
                <Link to="/">
                  <img src={img_8} className="white" />
                </Link>
                <p>Let your academic evolution begin here.</p>
              </div>
            </Col>
          </Row>
          <Row className="pt-120">
            <Col sm={9}>
              <div className="queke-link">
                <ul>
                  <li>
                    <Link to="/Privacy-Policy" className="footer-link-style">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Cookies Notice
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Do Not Sell My Personal Information
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Accessibility
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={3}>
              <div className="social-icon">
                <Link href="#action1" className="footer-link-style">
                  <img src={img_2} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_3} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_4} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_5} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_6} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_7} />
                </Link>
              </div>
            </Col>
          </Row>
          <div className="line-2"></div>
          <Row>
            <Col sm={12}>
              <p className="text-center pt-30 footer-bottom">
                © 2023 NUM8ERS • All Rights Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer mobile-view">
        <Container>
          <Row className="">
            <Col sm={6}>
              <div className="mobile-view-footer">
                <div className="footer-top">
                  <h4>Curriculum</h4>
                  <ul>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        AP
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        IB
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        GCSE
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        IGCSE
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-top">
                  <h4>Resources</h4>
                  <ul>
                    <li>
                      <Link to="/Blog" className="footer-link-style">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        Formulas
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        Books
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        Tutorials
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="mobile-view-footer">
                <div className="footer-top">
                  <h4>Plans</h4>
                  <ul>
                    <li>
                      <Link to="/Payment" className="footer-link-style">
                        Pricing
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer-top">
                  <h4>Support</h4>
                  <ul>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        Help Centre
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link href="#action1" className="footer-link-style">
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link to="/Contact-us" className="footer-link-style">
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
          <div className="line"></div>
          <Row className="mb-30">
            <Col sm={12}>
              <div className="footer-logo">
                <Link to="/">
                  <img src={img_1} className="d" />
                </Link>
                <Link to="/">
                  <img src={img_8} className="lite" />
                </Link>
                <p>Let your academic evolution begin here.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <div className="social-icon">
                <Link href="#action1" className="footer-link-style">
                  <img src={img_2} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_3} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_4} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_5} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_6} />
                </Link>
                <Link href="#action1" className="footer-link-style">
                  <img src={img_7} />
                </Link>
              </div>
            </Col>
            <Col sm={9}>
              <div className="queke-link mt-30 mb-30">
                <ul>
                  <li>
                    <Link to="/Privacy-Policy" className="footer-link-style">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Cookies Notice
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Do Not Sell My Personal Information
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Accessibility
                    </Link>
                  </li>
                  <li>
                    <Link href="#action1" className="footer-link-style">
                      Terms of Use
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="line-2"></div>
          <Row>
            <Col sm={12}>
              <p className="text-center pt-30 footer-bottom">
                © 2023 NUM8ERS • All Rights Reserved
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
