import { React, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from 'react-router-dom';
import Header from '../layout/header/Header';
import logo_d from '../img/logo/black-logo.png';
import logo_m_d from '../img/icon/mobile-view-logo.png';
import logo_w from '../img/logo/white-logo.png';
import Footer from './Footer';
import { useSendPasswordResetEmailMutation } from '../services/aplms';

const Forgot = () => {
  window.scrollTo(0, 0);
  const [error, setError] = useState({
    status: false,
    msg: '',
    type: '',
  });
  const [sendPasswordResetEmail] = useSendPasswordResetEmailMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get('email'),
    };
    if (actualData.email) {
      const res = await sendPasswordResetEmail(actualData);
      console.log(res);
      if (res.data && res.data.status === 'success') {
        document.getElementById('password-reset-email-form').reset();
        setError({
          status: true,
          msg: 'Password Reset Email Sent. Check Your Email !!',
          type: 'success',
        });
      }
      if (res.error && res.error.data.status === 'failed') {
        setError({ status: true, msg: res.error.data.message, type: 'error' });
      }
    } else {
      setError({
        status: true,
        msg: 'Please Provide Valid Email',
        type: 'error',
      });
    }
  };
  return (
    <>
      <section className="login pt-60 desktop-view">
        <Container>
          <Row className="pb-60">
            <Col
              sm={12}
              className="width-from">
              <div className="login-top-logo">
                <NavLink to="/">
                  <img
                    src={logo_w}
                    className="drack"
                  />
                </NavLink>
                <NavLink to="/">
                  <img
                    src={logo_d}
                    className="lite"
                  />
                </NavLink>
              </div>
              <div className="login-from">
                {error.status ? (
                  <p
                    className="p_alrat"
                    severity={error.type}>
                    {error.msg}
                  </p>
                ) : (
                  ''
                )}
                <Form
                  autoComplete="off"
                  onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-4"
                    controlId="formBasicEmail">
                    <Form.Label>Send Link to</Form.Label>
                    <Form.Control
                      id="email"
                      name="email"
                      label="Email Address"
                      placeholder="Email Address"
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="formBasicPassword">
                    <Form.Label>or</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Phone"
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>

      <section className="login mobile-view">
        <Header />
        <Container>
          <Row>
            <Col
              sm={6}
              className="width-from">
              <div className="login-top-logo">
                <NavLink to="/">
                  <img
                    src={logo_m_d}
                    className="drack"
                  />
                </NavLink>
                <NavLink to="/">
                  <img
                    src={logo_m_d}
                    className="lite-logo"
                  />
                </NavLink>
              </div>
              <h4 className="signup-page-mobile-view-top-header mt-30 mb-30">
                Discover. Dive. Dominate.
              </h4>
            </Col>
            <Col sm={6}>
              <div className="login-from">
                {error.status ? (
                  <p
                    className="p_alrat"
                    severity={error.type}>
                    {error.msg}
                  </p>
                ) : (
                  ''
                )}
                <Form
                  autoComplete="off"
                  onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-4"
                    controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      id="email"
                      name="email"
                      label="Email Address"
                      placeholder="Email Address"
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="formBasicPassword">
                    <Form.Label>Phone </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Phone"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-5"
                    controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      label="Remember me"
                    />
                  </Form.Group>
                  <p className="from-bottom-text">
                    Click reset password link on your email or phone
                  </p>
                  <Button
                    variant="primary"
                    type="submit">
                    Send
                  </Button>
                  <p className="from-bottom-text">
                    By continuing, you accept our <span>Terms of Use</span>, our{' '}
                    <span>Privacy Policy</span> and that your data is stored in
                    the cloud.
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Forgot;
